import Header from "../components/Header"
import Layout from "../components/Layout"
import image from "../media/partners_box.png"
import image1 from "../media/image1_box.png"
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import { useEffect, useState } from "react";
import { AiOutlineArrowUp } from "react-icons/ai"
import { useMediaQuery } from "react-responsive";
import Loading from "../components/Loading"
import ProgressBar from "@ramonak/react-progress-bar";
import { useContext } from "react";
import { ThemeContext } from "../contexts/themeContext";
import { getTargets } from "../api/database/user";
import trade from "../media/trade.png"
import discord_dark from "../media/discord_dark.png"
import twitter_dark from "../media/twitter_dark.png"
import instagram_dark from "../media/instagram_dark.png"
import telegram_dark from "../media/telegram_dark.png"
import medium_dark from "../media/medium_dark.png"
import discord_light from "../media/discord_light.png"
import twitter_light from "../media/twitter_light.png"
import instagram_light from "../media/instagram_light.png"
import telegram_light from "../media/telegram_light.png"
import medium_light from "../media/medium_light.png"
import Footer from "../components/Footer";

const Trade = () => {
    const { theme } = useContext(ThemeContext)
    const graphQuery = useMediaQuery({ query: '(min-width: 572px)' })
    const [fetched, setFetched] = useState(false)
    const [targets, setTargets] = useState(false)
    const [data, setData] = useState({
        currentUSDPrice: "",
        currentMarketCap: "",
        allTimeHighPerc: "",
        allTimeLowPerc: "",
        allTimeHigh: "",
        allTimeLow: "",
        totalVolume: "",
        h24PercChange: "",
        fluctuationUSD: ""
    })

    const getTokenData = async () => {
        let req = await fetch("https://api.coingecko.com/api/v3/coins/archive-ai")
        let res = await req.json()

        if (res.id == "archive-ai") {
            let obj = {
                currentUSDPrice: res.market_data.current_price.usd.toFixed(4),
                currentMarketCap: res.market_data.market_cap.usd,
                allTimeHighPerc: res.market_data.ath_change_percentage.usd.toFixed(2),
                allTimeLowPerc: res.market_data.atl_change_percentage.usd.toFixed(2),
                allTimeHigh: res.market_data.ath.usd.toFixed(4),
                allTimeLow: res.market_data.atl.usd.toFixed(4),
                totalVolume: res.market_data.total_volume.usd,
                h24PercChange: res.market_data.price_change_percentage_24h,
                fluctuationUSD: res.market_data.price_change_24h_in_currency.usd.toFixed(4)
            }

            setData(obj)
        }
    }

    useEffect(() => {
        if (!fetched) {
            getTokenData()
                .then(() => {
                    setTimeout(() =>{
                        setFetched(true)
                    }, 500)
                })
                .catch((err) => console.log(err))
        }
    }, [fetched])

    useEffect(() =>{
        async function fetchTargets(){
            const targs = await getTargets()
            if(targs.length > 0) setTargets(targs)
        }

        if(!targets) fetchTargets()
    }, [targets])

    return (
        <Layout>
            <Header text="Trade" />
            <div className="mt-[36px] flex flex-col lg:flex-row w-full pr-5">
                <div className="w-full lg:w-1/2 h-fit mr-5">
                    <p className={(theme == "dark" ? "text-lgray" : "text-ldark") + " text-lg font-semibold"}>Community news</p>
                    <div className={(theme == "dark" ? "scrollbar" : "scrollbarlight") + " w-full overflow-x-scroll flex px-3 py-4"}>
                        <CommunityNews1 title="Launch of ArcAI Beta" date="2023-02-13" link="https://medium.com/"/>
                    </div>


                    <div className="w-full grid grid-cols-2 gap-3 mt-5">
                        <div className="hidden col-span-1 h-[250px] lg:flex flex-col items-center justify-end rounded-[20px] p-3 bg-tokenomics bg-cover bg-center">
                            <a href="https://app.uniswap.org/#/swap?inputCurrency=0x5c8190b76e90b4dd0702740cf6eb0f7ee01ab5e9&outputCurrency=ETH">
                                <div className={(theme == "dark" ? "bg-white" : "bg-dark text-lwhite") + " rounded-full py-2 px-6 font-semibold cursor-pointer"}>
                                    TRADE ON UNISWAP
                                </div>
                            </a>
                        </div>

                        <div className={(theme == "dark" ? "bg-gradient-to-bl from-dark to-darker border border-dgray" : "bg-lighter border border-lgray") + " col-span-2 lg:col-span-1 h-full max-h-[250px] rounded-[20px] px-3 py-2 flex flex-col"}>
                            <p className={(theme == "dark" ? "text-neutral-300" : "text-ldark") + " text-xl font-semibold"}>Market Status</p>
                            <p className={(theme == "dark" ? "text-neutral-400" : "text-ldark") + " text-sm w-full text-right pr-3"}>24 hours</p>
                            <div className={(theme == "dark" ? "scrollbar" : "scrollbarlight") + " w-full h-full overflow-y-scroll pr-3"}>
                                <MarketRow title="Current Price (USD)" value={Number(data.currentUSDPrice)} up={data.fluctuationUSD} />
                                <MarketRow title="Total Volume (USD)" value={Number(data.totalVolume)} up={data.h24PercChange} />
                                <MarketRow title="Market Cap" value={Number(data.currentMarketCap)} up={"Nan"} />
                                <MarketRow title="All Time High" value={Number(data.allTimeHigh)} up={data.allTimeHighPerc} />
                                <MarketRow title="All Time Low" value={Number(data.allTimeLow)} up={data.allTimeLowPerc} />
                            </div>
                        </div>
                    </div>

                    <div className={(theme == "dark" ? "bg-gradient-to-bl from-dark to-darker border border-dgray" : "bg-lighter border border-lgray") + " w-full px-3 py-2 rounded-[20px] flex flex-col items-center 2sp:grid grid-cols-2 gap-3 mt-5 overflow-x-hidden noscroll h-auto 2sp:h-[318px]"}>
                        <div className="h-[350px] 2sp:h-full w-[300px] 2sp:w-auto col-span-1 flex items-center justify-center ">
                            <img src={trade} className="w-[80%] 2sp:w-[60%] lg:w-[80%]"/>
                        </div>

                        <p className={(theme == "dark" ? "text-neutral-300" : "text-ldark") + " font-semibold h-fit 2sp:h-[300px] overflow-y-scroll noscroll mt-3  2sp:mt-0"}>
                            <span className="text-lg">ArcAI About</span>
                            <br />
                            Our token, $ARCAI is integrated into our virtual marketplace that allows users to purchase AI plugins and datasets. ArcAI also rewards developers in $ARCAI by publishing AI datasets and browser plugins on the marketplace. Unlike other cryptocurrency projects who treat their tokens as stock, Archive AI's token is used as a means of payment within the Archive AI ecosystem for users and developers. Our decentralized approach for the Archive AI ecosystem provides other benefits such as access to exclusive features and discounts on our platform services. $ARCAI holders may also be eligible for rewards and bonuses for participating in platform activities.
                        </p>
                    </div>

                    <div className={(theme == "dark" ? "bg-gradient-to-bl from-dark to-darker border border-dgray" : "bg-lighter border border-lgray") + " w-full h-fit xxs:h-[250px] p-3 rounded-[20px] mt-5"}>
                        <p className={(theme == "dark" ? "text-neutral-300" : "text-ldark") + " font-semibold text-xl"}>Targets</p>
                        <div className="w-full grid grid-cols-1 xxs:grid-cols-2 gap-3 mt-2">
                            {
                                targets &&
                                targets.map((target, index) => (
                                    <Target 
                                        text={target.name} 
                                        value={target.min} 
                                        max={target.max} 
                                        plcMax={target.plcMax} 
                                        plcMin={target.plcMin} 
                                        key={index} 
                                    />
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className="w-full lg:w-1/2 h-fit mt-5 lg:mt-0">
                    <div className={(graphQuery ? "h-[515px] " : "h-[300px] ") + " w-full overflow-hidden relative"}>
                        {
                            fetched &&
                            <AdvancedRealTimeChart
                                height={graphQuery ? 515 : 300}
                                theme={theme}
                                width={"100%"}
                                symbol={"UNISWAP:ARCAIWETH"}
                                hide_top_toolbar={true}
                                hide_side_toolbar={true}
                                style={"2"}
                            >

                            </AdvancedRealTimeChart>
                        }
                        <Loading
                            loading={!fetched}
                            minHeight={graphQuery ? 515 : 300}
                        />
                    </div>

                    <div className={(theme == "dark" ? "bg-gradient-to-bl from-dark to-darker border border-dgray" : "bg-lighter border border-lgray") + " w-full p-3 rounded-[20px] mt-5 z-20"}>
                        <p className={(theme == "dark" ? "text-neutral-300" : "text-ldark") + " font-semibold text-xl"}>Task Board</p>
                        <p className={(theme == "dark" ? "text-neutral-300" : "text-ldark") + " font-semibold text-sm"}>Stay up to date with development in real time</p>

                        {
                            true ?
                                <div className="pr-3 w-full h-fit max-h-[236px] overflow-y-scroll scrollbar mt-2">
                                    <Task
                                        title="Validation Bug Fixes"
                                        text="Currently crafting a back-end portal for validation on our Database. This will make it easier for validators to verify data as truthful because we are building a sign in UI "
                                        status={0}
                                        eta="2 days"
                                    />
                                    <Task
                                        title="Bug Fixes"
                                        text="General bug fixes during Beta period (1 week). This includes platform upgrades and fixes."
                                        status={0}
                                        eta="1 week"
                                        classes="mt-2"
                                    />
                                    <Task
                                        title="ArcAI Beta Release"
                                        text="The release of the ArcAI platform Beta. Bug fixes will be in progress for the week following the release."
                                        status={1}
                                        eta="N/A"
                                        classes="mt-2"
                                    />
                                    
                                </div>
                                :
                                <></>
                        }
                    </div>

                    <div className={(theme == "dark" ? "bg-gradient-to-bl from-dark to-darker border border-dgray" : "bg-lighter border border-lgray") + " w-full h-[250px] p-3 rounded-[20px] mt-5 flex flex-col items-center"}>
                        <p className={(theme == "dark" ? "text-neutral-300" : "text-ldark") + " text-xl font-semibold"}>Community</p>

                        <p className={(theme == "dark" ? "text-neutral-400" : "text-dark") + " font-semibold mt-2 w-full max-w-[280px] text-center text-lg"}>
                            Join a vibrant community who aim to shape the future of AI
                        </p>

                        <div className="w-full h-[60px] overflow-x-scroll noscroll mt-5">
                            <div className="min-w-[500px] flex items-center justify-around h-full">
                                <a href="https://discord.com/invite/brdanAfS6s"><img src={theme == "dark" ? discord_dark : discord_light}/></a>
                                <a href="https://instagram.com/@archiveaierc"><img src={theme == "dark" ? instagram_dark : instagram_light}/></a>
                                <a href="https://twitter.com/ArchiveAIERC"><img src={theme == "dark" ? twitter_dark : twitter_light}/></a>
                                <a href="http://t.me/ArchiveAIERC"><img src={theme == "dark" ? telegram_dark : telegram_light}/></a>
                                <a href="https://medium.com/@archiveai"><img src={theme == "dark" ? medium_dark : medium_light}/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </Layout>
    )
}

const CommunityNews = ({
    classes = "",
    title = "",
    date = ""
}) => {
    const { theme } = useContext(ThemeContext)

    return (
        <div className={"min-w-[200px] flex flex-col items-center " + classes}>
            <img src={image} className="w-full" />
            <p className={(theme == "dark" ? "text-lgray" : "text-ldark") + " font-semibold"}>{title}</p>
            <p className="text-neutral-400 text-sm font-semibold">{date}</p>
        </div>
    )
}
const CommunityNews1 = ({
    classes = "",
    title = "",
    date = "",
    link = ""
}) => {
    const { theme } = useContext(ThemeContext)

    return (
        <a href={link}>
            <div className={"w-[200px] flex flex-col items-center " + classes}>
                <img src={image1} href="https://medium.com" className="w-full" />
                <p className={(theme == "dark" ? "text-lgray" : "text-ldark") + " font-semibold"}>{title}</p>
                <p className="text-neutral-400 text-sm font-semibold">{date}</p>
            </div>
        </a>
    )
}


const MarketRow = ({
    title = "",
    value = "",
    up = ""
}) => {
    const { theme } = useContext(ThemeContext)

    return (
        <div className="w-full flex justify-between items-center border-t-2 border-darker pt-1">
            <div className="w-fit h-fit">
                <p className={(theme == "dark" ? "text-neutral-400" : "text-ldark") + " text-sm font-semibold"}>{title}</p>
                <p className={(theme == "dark" ? "text-neutral-400" : "text-ldark") + " text-lg font-semibold"}>{value}</p>
            </div>

            <div className="w-fit h-fit flex items-center">
                {
                    !isNaN(up) && up != 0 &&
                    <AiOutlineArrowUp
                        size={20}
                        color={up > 0 ? "#74CB62" : "#D04530"}
                        className={up < 0 && "rotate-180"}
                    />
                }
                <p className={(!isNaN(up) ? up < 0 ? "text-lred" : up > 0 ? "text-lgreen" : (theme == "dark" ? "text-neutral-400" : "text-ldark") : (theme == "dark" ? "text-neutral-400" : "text-ldark")) + " font-semibold"}>
                    {!isNaN(up) ? up + "%" : "-"}
                </p>
            </div>
        </div>
    )
}

const Target = ({
    text = "",
    value = 0,
    max = 1,
    plcMax = "",
    plcMin = ""
}) => {
    const { theme } = useContext(ThemeContext)

    const getColor = () =>{
        let perc = (value * 100) / max

        return perc == 100 ? "#74CB62" : "#368ADE"
    }

    return (
        <div className="col-span-1">
            <p className={(theme == "dark" ? "text-neutral-300" : "text-ldark") + " font-semibold"}>{text}</p>
            <div className="w-full h-fit flex items-center mt-1">
                <div className="w-[90%] 2sp:w-[70%] rounded-full h-[20px] bg-darker overflow-hidden">
                    <ProgressBar
                        completed={value}
                        maxCompleted={max}
                        isLabelVisible={false}
                        baseBgColor={theme == "dark" ? "#121212" : "#E5E5E5"}
                        bgColor={getColor()}
                    />
                </div>
                <p className={(theme == "dark" ? "text-lgray" : "text-ldark") + " ml-2"}>{(plcMin != "" ? plcMin : value) + "/" + (plcMax != "" ? plcMax : max)}</p>
            </div>
        </div>
    )
}

const Task = ({
    title = "",
    text = "",
    status = 0,
    eta = "",
    classes = ""
}) => {
    const { theme } = useContext(ThemeContext)

    return (
        <div className={(theme == "dark" ? "bg-darker" : "bg-dwhite") + " w-full rounded-[15px] h-fit p-2 " + classes}>
            <div className="w-full flex items-center justify-between h-fit">
                <p className={(theme == "dark" ? "text-neutral-300" : "text-ldark") + " font-semibold"}>{title}</p>
                <div className="flex items-center">
                    <div className={"mr-1 w-[10px] h-[10px] rounded-full " + (status == 1 ? "bg-lgreen" : status == -1 ? "bg-lred" : "bg-lpurple")}>
                    </div>
                    <p className={(theme == "dark" ? "text-neutral-300" : "text-ldark") + " font-semibold"}>{status == 1 ? "Completed" : status == -1 ? "Rejected" : "In progress"}</p>
                </div>
            </div>
            <p className={(theme == "dark" ? "text-neutral-400" : "text-dark") + " w-full text-sm mt-1"}>
                {text}
            </p>

            <div className={(theme == "dark" ? "text-neutral-300" : "text-ldark") + " w-full h-fit flex justify-end font-semibold"}>
                ETA: {eta}
            </div>
        </div>
    )
}

export default Trade