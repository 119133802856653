import Header from "../components/Header"
import Layout from "../components/Layout"
import { useNavigate, useParams } from "react-router-dom"
import image from "../media/partners_box.png"
import { useEffect } from "react"
import { useContext } from "react"
import { PartnerContext } from "../contexts/partnershipContext"
import { getEntryById } from "../api/database/user"
import { ThemeContext } from "../contexts/themeContext"
import Footer from "../components/Footer";
import { AiOutlineLeft } from "react-icons/ai"
import { FrameContext } from "../contexts/frameContext"
import { UsernameContext } from "../contexts/userContext"
import imageBg from "../media/partners_box.png"

const PartnerPage = () => {
    const { partnership, setPartnership } = useContext(PartnerContext)
    const { theme } = useContext(ThemeContext)
    const { frame, setFrame } = useContext(FrameContext)
    const { loggedIn } = useContext(UsernameContext)
    const { partner } = useParams()
    const navigate = useNavigate()

    const getPartner = async () => {
        let fields = await getEntryById(partner)

        let fieldsOfFields = fields.fields
        setPartnership({
            name: fieldsOfFields.companyName,
            desc1: fieldsOfFields.description1,
            desc2: fieldsOfFields.description2,
            date: fieldsOfFields.joinDate,
            img: fieldsOfFields.profileImage.fields.file.url,
            sector: fieldsOfFields.sector,
            service: fieldsOfFields.service,
            users: fieldsOfFields.usersServed,
            solutions: fieldsOfFields.solutions,
            future: fieldsOfFields.future
        })
    }

    useEffect(() => {
        //console.log(partner)
        if (partner) getPartner()
    }, [partner])

    return (
        <Layout>
            <div className={"w-full flex items-center justify-between lg:pl-10 pr-5 xxs:pr-10 md:pr-20 pt-10"}>
                <h1 className={(theme == "dark" ? "text-white" : "text-dark") + " text-4xl font-semibold"}>
                    <span className="cursor-pointer" onClick={() => navigate("/")}>{"Partners > "}</span>
                    {partnership.name}
                </h1>
                {
                    frame != "register" && frame != "login" ?
                        loggedIn ?
                            <div
                                className="w-[50px] h-[50px] flex items-center justify-center rounded-full overflow-hidden cursor-pointer"
                                onClick={() => { setFrame("login") }}
                            >
                                <img src={image != "" ? image : imageBg} className="h-full" />
                            </div>
                            :
                            <p
                                className={(theme == "dark" ? "text-white" : "text-dark") + " text-xl font-semibold cursor-pointer"}
                                onClick={() => {
                                    setFrame("login")
                                }}
                            >
                                Login
                            </p>
                        :
                        <></>
                }
            </div>
            <h1 className={(theme == "dark" ? "text-lgray" : "text-ldark") + " text-xl font-semibold ml-4 mt-5"}>Joined: {partnership.date}</h1>
            <div className="w-full lg:flex h-fit mt-5">
                <div className="w-full lg:w-[500px] flex flex-col items-center">
                    <img src={partnership.img != "" ? partnership.img : image} className="h-[300px]" />
                    <div className={(theme == "dark" ? "border border-ldark bg-darker bg-opacity-30 text-neutral-400" : "bg-lighter border border-lgray text-dark") + " w-full rounded-[20px] h-fit mt-5 p-3 font-semibold"}>
                        {partnership.desc1}
                    </div>
                </div>

                <div className="w-full lg:w-[500px] lg:ml-5 mt-5 lg:mt-0">
                    <div className={(theme == "dark" ? "border border-ldark bg-darker bg-opacity-30" : "bg-lighter border border-lgray") + " w-full rounded-[20px] grid grid-cols-3 gap-3 px-2 py-3"}>
                        <div className={(theme == "dark" ? "text-neutral-400" : "text-dark") + " col-span-1 text-center font-semibold"}>
                            Sector
                        </div>

                        <div className={(theme == "dark" ? "text-neutral-400" : "text-dark") + " col-span-1 text-center font-semibold"}>
                            Service
                        </div>

                        <div className={(theme == "dark" ? "text-neutral-400" : "text-dark") + " col-span-1 text-center font-semibold"}>
                            Users served
                        </div>

                        <div className={(theme == "dark" ? "text-neutral-300" : "text-ldark") + " col-span-1 text-center font-semibold text-lg"}>
                            {partnership.sector}
                        </div>

                        <div className={(theme == "dark" ? "text-neutral-300" : "text-ldark") + " col-span-1 text-center font-semibold text-lg"}>
                            {partnership.service}
                        </div>

                        <div className={(theme == "dark" ? "text-neutral-300" : "text-ldark") + " col-span-1 text-center font-semibold text-lg"}>
                            {partnership.users}+
                        </div>
                    </div>

                    <div className={(theme == "dark" ? "border border-ldark bg-darker bg-opacity-30 text-neutral-400" : "bg-lighter border border-lgray text-dark") + " w-full rounded-[20px] h-fit mt-5 p-3 font-semibold"}>
                        {partnership.desc2}
                    </div>

                    <div className="w-full grid grid-cols-2 gap-5 mt-5">
                        <div className={(theme == "dark" ? "border border-ldark bg-darker bg-opacity-30" : "bg-lighter border border-lgray") + " col-span-1 rounded-[20px] h-fit mt-5 p-3"}>
                            <h1 className={(theme == "dark" ? "text-neutral-300" : "text-dark") + " font-semibold"}>Solutions</h1>
                            <p className={(theme == "dark" ? "text-neutral-400" : "text-ldark") + " w-full text-sm font-semibold max-h-[150px] overflow-y-scroll noscroll"}>
                                {partnership.solutions}
                            </p>
                        </div>

                        <div className={(theme == "dark" ? "border border-ldark bg-darker bg-opacity-30" : "bg-lighter border border-lgray") + " col-span-1 rounded-[20px] h-fit mt-5 p-3"}>
                            <h1 className={(theme == "dark" ? "text-neutral-300" : "text-dark") + " font-semibold"}>Future</h1>
                            <p className={(theme == "dark" ? "text-neutral-400" : "text-ldark") + " w-full text-sm font-semibold max-h-[150px] overflow-y-scroll noscroll"}>
                                {partnership.future}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Layout>
    )
}

export default PartnerPage