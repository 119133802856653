import { useState } from "react"
import { useContext, useEffect } from "react"
import { getPartnerships } from "../api/database/user"
import Header from "../components/Header"
import Layout from "../components/Layout"
import { ThemeContext } from "../contexts/themeContext"
import partnerImg from "../media/partners_box.png"
import { Link } from "react-router-dom"
import Footer from "../components/Footer";

const Partners = () =>{
    const { theme } = useContext(ThemeContext)
    const [partners, setPartnters] = useState([])
    const [desc, setDesc] = useState("")
    const [name, setName] = useState("")

    const getPartners = async() =>{
        let res = await getPartnerships()

        if(res.length > 0){
            if(res.length > 1){
                let randNum = Math.floor(Math.random() * res.length);
                let entry = res[randNum]
                setDesc(entry.description)
                setName(entry.name)
            }
            else{
                let entry = res[0]
                setDesc(entry.description)
                setName(entry.name)
            }

            setPartnters(res)
        }
    }

    useEffect(() =>{
        getPartners()
    }, [])

    return(
        <Layout>
            <Header text="Partners"/>
            <div className="lg:pl-16 pr-5 sp:pr-10 mt-[36px] flex justify-between flex-col-reverse sp:flex-row">
                <div className={(theme == "dark" ? "scrollbar" : "scrollbarlight") + " grid grid-cols-1 xxs:grid-cols-2 sm:grid-cols-3 sp:grid-cols-2 2xl:grid-cols-3 gap-5 h-[400px] sp:h-fit max-h-screen overflow-y-scroll pr-3 mt-5 sp:mt-0"}>
                    {
                        partners.map((partner, index) => (
                            <PartnerBox name={partner.name} sector={partner.sector} imageUrl={partner.url} id={partner.id} key={index}/>
                        ))
                    }
                </div>

                <div className="w-full sp:w-fit h-fit sp:ml-5">
                    <p className={(theme == "dark" ? "text-lgray" : "text-ldark") + " text-lg font-semibold ml-5"}>Partners Preview</p>
                    <div className="flex justify-between sp:justify-start flex-col 2sp:flex-row sp:flex-col items-center 2sp:items-start">
                        <div className={(theme == "dark" ? "bg-ldark bg-opacity-30" : "bg-lighter") + " w-full 2sp:w-[300px] rounded-[20px] p-1 mt-3 h-[200px] sp:h-fit"}>
                            <div className={(theme == "dark" ? "bg-darker" : "bg-dwhite") + " w-full h-full overflow-y-scroll noscroll rounded-[20px] pt-2 pb-5 sp:pb-10 px-5"}>
                                <h1 className={(theme == "dark" ? "text-white" : "text-dark") + " font-semibold w-full text-center"}>{name}</h1>
                                <p className={(theme == "dark" ? "text-lgray" : "text-ldark") + " text-sm mt-2"}>
                                    {desc}
                                </p>
                            </div>
                        </div>

                        <div className={(theme == "dark" ? "bg-ldark bg-opacity-30" : "bg-lighter") + " w-full as:w-[300px] rounded-[20px] p-1 mt-3 flex flex-col items-center"}>
                            <div className={(theme == "dark" ? "bg-darker" : "bg-dwhite") + " w-full rounded-[20px] pt-2 pb-10 px-5"}>
                                
                            </div>

                            <div className={(theme == "dark" ? "bg-white" : "bg-dark text-lwhite") + " px-3 py-1 rounded-full cursor-pointer font-semibold mt-3 w-fit"}>
                                Discover now
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </Layout>
    )
}

const PartnerBox = ({
    name = "",
    sector = "",
    imageUrl = "",
    id = ""
}) =>{
    const { theme } = useContext(ThemeContext)
    
    return(
        <div className={(theme == "dark" ? "bg-gradient-to-bl from-dark to-darker border border-ldark" :"bg-gradient-to-bl from-lwhite to-dwhite border border-lgray") + " rounded-xl p-3 flex flex-col items-center col-span-1 max-h-[300px] max-w-[250px] as:max-w-[300px] xxs:max-w-[250px]"}>
            <div className="w-full flex items-center">
                <div className={"w-[25px] h-[25px] rounded-full overflow-hidden flex items-center justify-center " + (theme == "dark" ? "bg-darker" : "bg-lighter")}>
                    <img className="w-full " src={imageUrl != "" ? imageUrl : partnerImg}/>
                </div>
                <p className={(theme == "dark" ? "text-white" : "text-dgray") + " text-lg ml-2 font-semibold"}>{name}</p>
            </div>

            <img className="h-[130px] mt-2" src={imageUrl != "" ? imageUrl : partnerImg}/>

            <p className={(theme == "dark" ? "text-lgray" : "text-ldark") + " text-sm mt-3"}>{sector}</p>

            <Link to={id}>
                <div className="px-3 py-1 text-white rounded-full bg-lblue cursor-pointer font-semibold mt-2">
                    Learn more
                </div>
            </Link>
        </div>
    )
}

export default Partners