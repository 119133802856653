import { createContext, useState, useEffect } from "react";

const getInitialLang = () => {
    if (typeof window !== "undefined" && window.localStorage) {
        const storedPrefs = window.localStorage.getItem("archai-current-frame");
        if (typeof storedPrefs === "string") {
            return storedPrefs;
        }
    }

    return "dashboard";
};

export const FrameContext = createContext(null)

export const FrameProvider = ({ initialTheme, children }) => {
    const [frame, setFrame] = useState(getInitialLang);

    const checkLang = (existing) => {
        const root = window.document.documentElement;
        root.classList.add(existing);
        localStorage.setItem("archai-current-frame", existing);
    };

    if (initialTheme) {
        checkLang(initialTheme);
    }

    useEffect(() => {
        checkLang(frame);
    }, [frame]);

    return (
        <FrameContext.Provider value={{ frame, setFrame }}>
            {children}
        </FrameContext.Provider>
    );
};