import { createContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getEntryById } from "../api/database/user";

export const PartnerContext = createContext(null)

export const PartnerProvider = ({ children }) => {
    const { partner } = useParams()
    const [partnership, setPartnership] = useState({
        name: "",
        desc1: "",
        desc2: "",
        date: "",
        img: "",
        sector: "",
        service: "",
        users: "",
        solutions: "",
        future: ""
    });

    return (
        <PartnerContext.Provider value={{ partnership, setPartnership }}>
            {children}
        </PartnerContext.Provider>
    );
};