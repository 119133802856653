import { useRef } from "react"
import { useEffect } from "react"

const ChoicesMenu = ({
    choices =[],
    actions = [],
    opened = false,
    closePanel = () => {},
    top = 0,
    left = 0
}) =>{
    const ref = useRef(null)
    
    useEffect(() =>{
        document.addEventListener("mousedown", (e) => {
            if (!ref.current.contains(e.target)) closePanel()
        })
    }, [])

    return(
        <div 
            className={(opened ? "absolute" : "hidden") +  " w-fit max-w-[200px] rounded-xl bg-gradient-to-r from-dark to-darker overflow-hidden"}
            style={{
                top: top,
                left: left
            }}
            ref={ref}
        >
            {
                choices.map((choice, index) =>(
                    <div 
                        className="bg-transparent hover:bg-lgray hover:text-black bg-opacity-5 text-white font-semibold p-2 cursor-pointer transition-colors" 
                        key={index} 
                        onClick={() =>{
                            actions[index]()
                            closePanel()
                        }}
                    >
                        {choice}
                    </div>
                ))
            }
        </div>
    )
}

export default ChoicesMenu