import Header from "../components/Header"
import Layout from "../components/Layout"
import { BsCardImage } from "react-icons/bs"
import imageBg from "../media/partners_box.png"
import { useContext, useEffect } from "react"
import { UsernameContext } from "../contexts/userContext"
import { FrameContext } from "../contexts/frameContext"
import { useRef } from "react"
import { useState } from "react"
import { register, login, updateProfile } from "../api/database/user"
import { AiFillEye, AiTwotoneEyeInvisible } from "react-icons/ai"
import Loading from "../components/Loading"
import Popup from "../components/Popup"
import { toast } from "react-toastify"
import { ThemeContext } from "../contexts/themeContext"
import Footer from "../components/Footer";
import Cookies from 'universal-cookie';

const Settings = () => {
    const { username, setUsername, image, setImage, email, setEmail, loggedIn, setLoggedIn } = useContext(UsernameContext)
    const { frame, setFrame } = useContext(FrameContext)
    const { theme } = useContext(ThemeContext)
    const userRef = useRef(null)
    const inputRef = useRef(null)
    const emailRef = useRef(null)
    const passRef = useRef(null)
    const confirmRef = useRef(null)
    const [chosenImg, setChosenImg] = useState("")
    const [passVisible1, setVisible1] = useState(false)
    const [passVisible2, setVisible2] = useState(false)
    const [openPopup, setOpenPopup] = useState(false)
    const [loading, setLoading] = useState(false)


    const updateData = async (password) => {
        let emailToSend = emailRef.current.value != "" ? emailRef.current.value : email
        let usernameToSend = userRef.current.value != "" ? userRef.current.value : username
        let imageToSend = chosenImg != "" ? chosenImg : image
        let passwordToSend = passRef.current.value != password ? passRef.current.value : password

        let res = await updateProfile(email, username, password, emailToSend, usernameToSend, imageToSend, passwordToSend)

        if (!res.failed) {
            let user = res.user
            setUsername(user.username)
            setImage(user.imageData)
            setEmail(user.email)

            userRef.current.value = ""
            emailRef.current.value = ""
            passRef.current.value = ""

            toast("Successfully updated", {
                type: "success"
            })
        }
        else {
            toast(res.error, {
                type: "error"
            })
        }

        setLoading(false)
    }

    const checkAndRegister = async () => {
        if (emailRef.current.value != "" && userRef.current.value != "" && passRef.current.value != "" && userRef.current.value.toLowerCase() != "anonymous") {
            if(confirmRef.current.value == passRef.current.value){
                let res = await register(emailRef.current.value, userRef.current.value, passRef.current.value)

                if (!res.failed) {
                    let user = res.user
                    setEmail(user.email)
                    setUsername(user.username)
                    setImage(user.imageData)

                    setLoggedIn(true)
                    userRef.current.value = ""
                    emailRef.current.value = ""
                    passRef.current.value = ""

                    toast("Successfully registered", {
                        type: "success"
                    })
                }
                else {
                    toast(res.error, {
                        type: "error"
                    })
                }
            }
            else{
                toast("Passwords do not coincide", {
                    type: "error"
                })
            }
        }

        setLoading(false)
    }

    const checkAndLogin = async () => {
        if (emailRef.current.value != "" && userRef.current.value != "" && passRef.current.value != "" && userRef.current.value.toLowerCase() != "anonymous") {
            let res = await login(emailRef.current.value, userRef.current.value, passRef.current.value)

            if (!res.failed) {
                let user = res.user
                setEmail(user.email)
                setUsername(user.username)
                setImage(user.imageData)

                setLoggedIn(true)
                userRef.current.value = ""
                emailRef.current.value = ""
                passRef.current.value = ""

                toast("Successfully logged in", {
                    type: "success"
                })
            }
            else {
                toast(res.error, {
                    type: "error"
                })
            }
        }

        setLoading(false)
    }


    useEffect(() => {
        if (openPopup && !loggedIn) setOpenPopup(false)
    }, [openPopup])

    return (
        <Layout>
            <Header text="Settings" />

            <div className="2xl:pl-16 pr-5 xxs:pr-10 mt-[36px] flex flex-col 1xl:flex-row items-center xl:items-start">
                <div className={(theme == "dark" ? "bg-gradient-to-bl from-dark to-darker border border-dgray" : "bg-lighter border border-lgray") + " relative rounded-[20px] w-full max-w-[800px] py-20 px-5 flex items-center flex-col overflow-hidden"}>
                    <p className={(theme == "dark" ? "text-lgray" : "text-ldark") + " text-3xl font-semibold"}>{!loggedIn ? frame.charAt(0).toUpperCase() + frame.slice(1) : "Customize profile"}</p>
                    {
                        loggedIn &&
                        <div className={(theme == "dark" ? "border border-ldark" : "border border-lgray") + " relative rounded-2xl flex items-center justify-center overflow-hidden group w-fit mt-3"} onClick={() => inputRef.current.click()}>
                            <img className="w-[150px] h-[150px]" src={image == "" ? chosenImg == "" ? imageBg : chosenImg : image} />
                            <div className="absolute w-[150px] h-[150px] bg-lgray bg-opacity-20 hidden group-hover:flex items-center justify-center cursor-pointer rounded-2xl">
                                <BsCardImage size={70} color="#a6a6a6" />
                            </div>
                            <input
                                type={"file"}
                                ref={inputRef}
                                hidden
                                onChange={(e) => {
                                    let file = e.target.files[0]

                                    if (file.type.includes("image")) {
                                        const reader = new FileReader()

                                        reader.onload = (evt) => {
                                            console.log(evt.target.result)
                                            setChosenImg(evt.target.result)
                                        }

                                        reader.readAsDataURL(file)
                                    }
                                }}
                            />
                        </div>
                    }

                    <div className="w-full max-w-[400px] mt-7">
                        <p className={(theme == "dark" ? "text-lgray" : "text-ldark") + " font-semibold"}>Username</p>
                        <input
                            ref={userRef}
                            type={"text"}
                            placeholder={username}
                            className={(theme == "dark" ? "bg-dark bg-opacity-20 border border-ldark text-lgray" : "bg-dwhite border border-lgray text-ldark") + " px-2 py-1 text-lg font-semibold mt-2 w-full rounded-lg appearence-none focus:outline-0"}
                        />
                    </div>

                    <div className="w-full max-w-[400px] mt-4">
                        <p className={(theme == "dark" ? "text-lgray" : "text-ldark") + " font-semibold"}>Email</p>
                        <input
                            ref={emailRef}
                            type={"text"}
                            placeholder={email == "" ? "Email" : email}
                            className={(theme == "dark" ? "bg-dark bg-opacity-20 border border-ldark text-lgray" : "bg-dwhite border border-lgray text-ldark") + " px-2 py-1 text-lg font-semibold mt-2 w-full rounded-lg appearence-none focus:outline-0"}
                        />
                    </div>

                    <div className="relative w-full max-w-[400px] mt-4">
                        <p className={(theme == "dark" ? "text-lgray" : "text-ldark") + " font-semibold"}>Password</p>
                        <input
                            ref={passRef}
                            type={passVisible1 ? "text" : "password"}
                            placeholder="Password"
                            className={(theme == "dark" ? "bg-dark bg-opacity-20 border border-ldark text-lgray" : "bg-dwhite border border-lgray text-ldark") + " px-2 py-1 text-lg font-semibold mt-2 w-full rounded-lg appearence-none focus:outline-0"}
                        />
                        {
                            !passVisible1 ?
                                <AiFillEye
                                    color={theme == "dark" ? "#CBCBCB" : "#6b6c6d"}
                                    size={25}
                                    className="absolute right-2 bottom-[6px] cursor-pointer"
                                    onClick={() => setVisible1(true)}
                                />
                                :
                                <AiTwotoneEyeInvisible
                                    color={theme == "dark" ? "#CBCBCB" : "#6b6c6d"}
                                    size={25}
                                    className="absolute right-2 bottom-[6px] cursor-pointer"
                                    onClick={() => setVisible1(false)}
                                />
                        }
                    </div>

                    {
                        frame == "register" &&
                        <div className="relative w-full max-w-[400px] mt-4">
                            <p className={(theme == "dark" ? "text-lgray" : "text-ldark") + " font-semibold"}>Confirm Password</p>
                            <input
                                ref={confirmRef}
                                type={passVisible2 ? "text" : "password"}
                                placeholder="Confirm"
                                className={(theme == "dark" ? "bg-dark bg-opacity-20 border border-ldark text-lgray" : "bg-dwhite border border-lgray text-ldark") + " px-2 py-1 text-lg font-semibold mt-2 w-full rounded-lg appearence-none focus:outline-0"}
                            />
                            {
                                !passVisible2 ?
                                    <AiFillEye
                                        color={theme == "dark" ? "#CBCBCB" : "#6b6c6d"}
                                        size={25}
                                        className="absolute right-2 bottom-[6px] cursor-pointer"
                                        onClick={() => setVisible2(true)}
                                    />
                                    :
                                    <AiTwotoneEyeInvisible
                                        color={theme == "dark" ? "#CBCBCB" : "#6b6c6d"}
                                        size={25}
                                        className="absolute right-2 bottom-[6px] cursor-pointer"
                                        onClick={() => setVisible2(false)}
                                    />
                            }
                        </div>
                    }

                    <div className="flex">
                        <div
                            className={(theme == "dark" ? "text-neutral-100" : "text-dark") + (loggedIn ? " mr-1" : "") + " w-fit px-3 py-2 text-center bg-dark bg-opacity-20 hover:bg-lblue hover:bg-opacity-100 transition-colors rounded-xl mt-7 cursor-pointer border border-ldark font-semibold"}
                            onClick={() => {
                                if (loggedIn) setOpenPopup(true)
                                else {
                                    setLoading(true)
                                    if (frame == "register") checkAndRegister()
                                    else checkAndLogin()
                                }
                            }}
                        >
                            {loggedIn ? "Edit" : frame.charAt(0).toUpperCase() + frame.slice(1)}
                        </div>
                        
                        {
                            loggedIn &&
                            <div
                                className={(theme == "dark" ? "text-neutral-100" : "text-dark") + " w-fit px-3 py-2 text-center bg-dark bg-opacity-20 hover:bg-lblue hover:bg-opacity-100 transition-colors rounded-xl mt-7 cursor-pointer border border-ldark font-semibold ml-1"}
                                onClick={() => {
                                    const cookies = new Cookies()
                                    setFrame("login")
                                    setLoggedIn(false)
                                    setUsername("Anonymous")
                                    setEmail("")
                                    setImage("")
                                    cookies.remove("session")
                                }}
                            >
                                Logout
                            </div>
                        }
                    </div>

                    {
                        !loggedIn ?
                            frame == "login" ?
                                <p className={(theme == "dark" ? "text-lgray" : "text-ldark") + " font-semibold mt-2"}>
                                    You don't have an account? <span className="text-lblue underline cursor-pointer" onClick={() => setFrame("register")}>Register</span>
                                </p>
                                :
                                <p className={(theme == "dark" ? "text-lgray" : "text-ldark") + " font-semibold mt-2"}>
                                    You have an account? <span className="text-lblue underline cursor-pointer" onClick={() => setFrame("login")}>Login</span>
                                </p>
                            :
                            <></>
                    }

                    <Popup
                        closePopup={() => setOpenPopup(false)}
                        visible={openPopup}
                        execute={(pass) => {
                            setLoading(true)
                            updateData(pass)
                            setOpenPopup(false)
                        }}
                    />
                    <Loading
                        loading={loading}
                        minHeight={408}
                    />
                </div>

                {/*
                    <div className="rounded-[20px] bg-ldark bg-opacity-20 border border-ldark w-full max-w-[800px] 1xl:w-[300px] ml-0 1xl:ml-5 mt-5 1xl:mt-0 h-fit p-2">
                        <h1 className="text-lgray font-semibold text-xl">Purchase History</h1>

                        <div className="w-full flex justify-center">
                            <div className="mt-5 w-full max-w-[600px] grid grid-cols-1 sm:grid-cols-2 1xl:grid-cols-1 gap-2 h-fit max-h-[214px] overflow-y-scroll noscroll">
                                <PurchaseItem name="Product Name" amount={186.45}/>
                                <PurchaseItem name="Product Name" amount={186.45}/>
                                <PurchaseItem name="Product Name" amount={186.45}/>
                                <PurchaseItem name="Product Name" amount={186.45}/>
                                <PurchaseItem name="Product Name" amount={186.45}/>
                                <PurchaseItem name="Product Name" amount={186.45}/>
                                <PurchaseItem name="Product Name" amount={186.45}/>
                                <PurchaseItem name="Product Name" amount={186.45}/>
                                <PurchaseItem name="Product Name" amount={186.45}/>
                            </div>
                        </div>

                        <div className="w-full h-fit border-2 border-ldark mt-2 rounded-full"/>

                        <div className="mt-2 w-full h-fit">
                            <p className="text-lgray font-semibold text-xl">Total USD Amount</p>

                            <div className="bg-gradient-to-r from-dark to-darker px-5 border border-ldark py-1 rounded-[15px] text-neutral-200 text-xl mt-2">
                                $559.39
                            </div>
                        </div>
                    </div>
                */}
            </div>
            <Footer />
        </Layout>
    )
}

const PurchaseItem = ({
    name = "",
    amount = 0
}) => {
    return (
        <div className={"w-full rounded-[15px] bg-gradient-to-r from-dark to-darker flex items-center px-2 as:px-5 border border-ldark py-1 col-span-1 "}>
            <img className="w-[50px] h-[50px] rounded-full" src="" />
            <div className="ml-5">
                <p className="text-neutral-200 text-xl">{name}</p>
                <p className="text-neutral-200 text-lg">{amount}</p>
            </div>
        </div>
    )
}

export default Settings