import { useContext, useEffect, useState } from "react";
import { AiFillCaretRight } from "react-icons/ai"
import { GiHamburgerMenu } from "react-icons/gi"
import { ThemeContext, ThemeProvider } from './contexts/themeContext';
import { FrameProvider, FrameContext } from './contexts/frameContext'
import { UsernameProvider } from "./contexts/userContext";
import Proxy from "./components/Proxy";
import { useMediaQuery } from "react-responsive";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import logo_darkmode from "./media/logo_darkmode.png"
import logo_lightmode from "./media/logo_lightmode.png"
import { BrowserRouter } from "react-router-dom";
import ThemeSwitch from "./components/ThemeSwitch";
import { getNotification } from "./api/database/user";

function App() {
  const [menuOpened, openMenu] = useState(false)
  const menuQuery = useMediaQuery({ query: '(min-width: 1334px)' })

  return (
    <ThemeProvider>
      <FrameProvider>
        <UsernameProvider>
          <BrowserRouter>
            <FullContainer>
              <ToastContainer
                position="top-right"
                autoClose={2000}
                newestOnTop={true}
                pauseOnHover
                limit={3}
                theme="dark"
              />
              {
                menuQuery &&
                <SiderBar/>
              }
              <ProxyContainer menuQuery={menuQuery}>
                <Proxy />
              </ProxyContainer>
              {
                !menuQuery &&
                <MobileMenu menuOpened={menuOpened} openMenu={openMenu} />
              }
            </FullContainer>
          </BrowserRouter>
        </UsernameProvider>
      </FrameProvider>
    </ThemeProvider>
  );
}

const SideBarLink = ({
  txt = "",
  link = "",
  similarLink = "",
  classes = ""
}) => {
  const { frame, setFrame } = useContext(FrameContext)
  const { theme } = useContext(ThemeContext)

  return (
    <div
      className={`flex items-center justify-between pl-5 ${classes} ${(link == frame || similarLink == frame) ? (theme == "dark" ? "gradient" : "bg-dwhite") + " rounded-lg" : (theme == "dark" ? "hovergradient" : "hover:bg-dwhite") + " transition-colors rounded-l-lg"} h-[46px] cursor-pointer`}
      onClick={() => { if (link != frame) setFrame(link) }}
    >
      <p className={(theme == "dark" ? "text-white" : "text-dark") + " text-xl font-semibold"}>{txt}</p>

      {
        (link == frame || similarLink == frame) &&
        <div className="flex items-center h-full">
          <AiFillCaretRight size={20} color="#6b6c6d" className="mr-2" />
          <div className="h-[46px] w-[6px] bg-lblue rounded-lg" />
        </div>
      }
    </div>
  )
}

const MenuLink = ({
  txt = "",
  link = "",
  similarLink = "",
  classes = "",
  closeMenu = () => {}
}) => {
  const { frame, setFrame } = useContext(FrameContext)
  const { theme } = useContext(ThemeContext)

  return (
    <div
      className={(theme == "dark" ? "border border-ldark" : "border border-lgray") + ` col-span-1 flex items-center flex-col justify-between rounded-[5px] ${classes} ${link == frame || similarLink == frame ? (theme == "dark" ? "bg-gradient-to-bl from-dark to-darker border border-ldark pt-2" : "bg-dwhite text-ldark border border-lgray pt-2") : "py-2"} w-[200px] cursor-pointer z-50`}
      onClick={() => { 
        if(link != frame){
          setFrame(link)
          closeMenu()
        }
      }}
    >
      <p className={(theme == "dark" ? "text-white" : "text-dark") + " text-xl font-semibold"}>{txt}</p>

      {
        (link == frame || similarLink == frame) &&
        <div className="w-full h-[6px] bg-lblue rounded-lg mt-2" />
      }
    </div>
  )
}

const Logo = () => {
  const { theme } = useContext(ThemeContext)

  return (
    <a href="https://aiarchive.io/" className="w-[150px] h-fit"><img className="w-[150px] mt-[15px] mt-8" src={theme == "dark" ? logo_darkmode : logo_lightmode} /></a>
  )
}

const FullContainer = ({ children }) => {
  const { theme } = useContext(ThemeContext)

  return (
    <div className={"w-full h-screen flex flex-col xl:flex-row " + (theme == "dark" ? "bg-darker" : "bg-lighter")}>
      {children}
    </div>
  )
}

const ProxyContainer = ({ children, menuQuery = true }) => {
  const { theme } = useContext(ThemeContext)

  return (
    <div className={(theme == "dark" ? "bg-dark scrollbar" : "bg-dwhite scrollbarlight") + " w-full h-full h-screen overflow-y-scroll " + (!menuQuery && "mt-[70px]")}>
      {children}
    </div>
  )
}

const MobileMenu = ({
  menuOpened = false,
  openMenu = () => { }
}) => {
  const { theme } = useContext(ThemeContext)

  return (
    <div className={(theme == "dark" ? "bg-darker" : "bg-lwhite") + " fixed top-0 w-full h-[70px] " + (menuOpened ? "overflow-y-visible" : "overflow-y-hidden")}>
      <div className="w-full h-[70px] flex items-center justify-between px-3 xxs:px-10 py-5">
        <div className="h-full flex items-center">
          <a href="https://aiarchive.io/" className="w-fit h-fit"><img src={theme == "dark" ? logo_darkmode : logo_lightmode} className="w-[150px] h-full" /></a>
          <ThemeSwitch classes="ml-3" />
        </div>
        <GiHamburgerMenu color={theme == "dark" ? "#FFF" : "#000"} size={30} onClick={() => openMenu(!menuOpened)} />
      </div>

      {
        menuOpened &&
        <div className={(theme == "dark" ? "bg-darker" : "bg-lwhite") + " absolute w-full flex flex-col items-center pt-5 pb-8  rounded-b-[20px]"}>
          <div className="w-fit max-w-[400px] grid grid-cols-1 xxs:grid-cols-2 gap-3">
            <MenuLink txt="Dashboard" link="dashboard" closeMenu={() => openMenu(false)}/>
            <MenuLink txt="Partners" link="partners" closeMenu={() => openMenu(false)}/>
            <MenuLink txt="VirtualStore" link="virtual" closeMenu={() => openMenu(false)}/>
            <MenuLink txt="Statistics" link="stats" closeMenu={() => openMenu(false)}/>
            <MenuLink txt="Trade" link="trade" closeMenu={() => openMenu(false)}/>
            <MenuLink txt="Settings" link="login" closeMenu={() => openMenu(false)}/>
          </div>
        </div>
      }
    </div>
  )
}

const SiderBar = () => {
  const { theme } = useContext(ThemeContext)

  return(
    <div className="pl-10 h-screen overflow-y-scroll noscroll">
      <div className="h-fit pb-10">
        <div className="w-[200px]">
          <Logo />
        </div>

        <SideBarLink txt="Dashboard" classes="mt-10" link="dashboard" />
        <SideBarLink txt="Partners" classes="mt-3" link="partners" />
        <SideBarLink txt="VirtualStore" classes="mt-3" link="virtual" />
        <SideBarLink txt="Statistics" classes="mt-3" link="stats" />
        <SideBarLink txt="Trade" classes="mt-3" link="trade" />
        <SideBarLink txt="Settings" classes="mt-3" link="login" similarLink="register" />

        <div className="w-[174px] mt-10">
          <a className={(theme == "dark" ? "text-white" : "text-dark") + " text-xl font-semibold"} href="https://medium.com/@ArchiveAI">Docs</a><br />
          <a href=""><p className={(theme == "dark" ? "text-white" : "text-dark") + " text-xl font-semibold mt-3"}>Help (FAQ)</p></a>
          <div className="mt-3 flex items-center">
            <p className={(theme == "dark" ? "text-white" : "text-dark") + " font-semibold text-xl"}>Dark Mode</p>
            <ThemeSwitch classes="ml-2"/>
          </div>
        </div>

        <div className="w-fit h-fit pr-10">
          <Notification/>
        </div>
      </div>
    </div>
  )
}

const Notification = () =>{
  const { theme } = useContext(ThemeContext)
  const id = "5cknQWy8feeuCP44z3Jq1K"
  const [data, setData] = useState({
    title: "",
    banner: "",
    fetched: false
  })

  const assignNotification = async() =>{
    const obj = await getNotification(id)
    setData(obj)
  }

  useEffect(() =>{
    if(!data.fetched) assignNotification()
  }, [data])

  return(
    data.fetched &&
    <div className={(theme == "dark" ? "bg-dark" : "bg-dwhite") + " rounded-[15px] py-2 mt-10 w-[174px] h-[238px] overflow-y-scroll noscroll"}>
      <div className={"px-3 border-b border-lgray flex items-center"}>
        <div className="rounded-full w-[10px] h-[10px] bg-lred"></div>
        <p className={(theme == "dark" ? "text-white" : "text-dark") + " text-lg font-semibold ml-2"}>{data.title}</p>
      </div>
      <p className={(theme == "dark" ? "text-white" : "text-dark") + " text-sm px-1 mt-2"}>
        {data.description}
      </p>
    </div>
  )
}

export default App;
