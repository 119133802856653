import { BarLoader } from "react-spinners";

const Loading = ({
    loading = false,
    minHeight = 0,
    maxHeight = 0
}) =>{
    return(
        <div className={`LOADING absolute bg-darker bg-opacity-60 left-0 top-0 w-full h-full ${minHeight ? `min-h-[${minHeight}px]` : ""} ${maxHeight ? "max-h-[" + maxHeight + "px]" : ""} ${loading ? "flex" : "hidden"} items-center justify-center`}>
            <BarLoader
                color="#368ADE"
                loading={true}
                width={200}
            />
        </div>
    )
}

export default Loading