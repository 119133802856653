import { useContext } from "react"
import { ThemeContext } from "../contexts/themeContext"

const Footer = () =>{
    const { theme } = useContext(ThemeContext)

    return(
        <div className={(theme == "dark" ? "text-neutral-200" : "text-darker") + " w-full hidden flex-col items-center text-2xl font-semibold mt-5"}>
            Product made by
            <a 
                href="https://portfolio-lochki02.vercel.app/"
                className="text-underlined text-lblue font-semibold"
            >
                Davide Giacò | Lochki#2306
            </a>
        </div>
    )
}

export default Footer