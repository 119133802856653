import { createContext, useEffect, useState } from "react";
import { authLogin } from "../api/database/user";

const getInitialUsername = () => {
    return "Anonymous";
};

const getInitialImage = () => {
    return "";
};

const getInitialEmail = () => {
    return "";
};

export const UsernameContext = createContext(null)

export const UsernameProvider = ({ children }) => {
    const [username, setUsername] = useState(getInitialUsername);
    const [image, setImage] = useState(getInitialImage);
    const [email, setEmail] = useState(getInitialEmail);
    const [loggedIn, setLoggedIn] = useState(false);
    const [firstFetch, setFetched] = useState(false)

    useEffect(() => {
        if (!loggedIn && username.toLowerCase != "anonymous" && image != "" && email != "") setLoggedIn(true)
    }, [username, image, email])

    useEffect(() => {
        async function executeAuthLogin(){
            const user = await authLogin()

            if(user){
                setLoggedIn(true)
                setUsername(user.username)
                setEmail(user.email)
                setImage(user.imageData)
            }
        }

        if (!firstFetch){
            executeAuthLogin()
            setFetched(true)
        }
    }, [firstFetch])

    return (
        <UsernameContext.Provider value={{
            username: username,
            setUsername: setUsername,
            image: image,
            setImage: setImage,
            email: email,
            setEmail: setEmail,
            loggedIn: loggedIn,
            setLoggedIn: setLoggedIn
        }}>
            {children}
        </UsernameContext.Provider>
    );
};