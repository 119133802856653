import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useState } from 'react';
import { useEffect } from 'react';
import Loading from '../../components/Loading';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const Graph = ({
    dataArray = []
}) => {
    const options = {
        responsive: false,
        plugins: {
            title: {
                display: true,
                text: 'Weekly Pushes',
            }
        }
    }

    const dummyLables = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
    const loadingData = {
        dummyLables,
        datasets: [
            {
                label: "Pushes",
                data: [0,0,0,0,0,0,0],
                backgroundColor: "#368ADE"
            }
        ]
    }
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(loading){
            if(dataArray.length > 0){
                let groups = []

                for (let i = 0; i < dataArray.length; i++) {
                    if (i == 0) {
                        let date = new Date(dataArray[0].date)
                        groups.push({
                            timestamp: dataArray[i].date,
                            counter: 1,
                            day: date.toLocaleString("default", { day: "2-digit" })
                        })
                    }
                    else {
                        let date = new Date(dataArray[i].date)
                        let day = date.toLocaleString("default", { day: "2-digit" })
                        let found = false
                        for (let i = 0; i < groups.length; i++) {
                            if (groups[i].day == day) {
                                groups[i].counter++
                                found = true
                                break
                            }
                        }

                        if (!found) {
                            groups.push({
                                timestamp: dataArray[i].date,
                                counter: 1,
                                day: day
                            })
                        }
                    }
                }

                groups.sort((a, b) => { return a.timestamp > b.timestamp ? 1 : -1 })

                if (groups.length < 7) {
                    let daysNeeded = 7 - groups.length
                    let lastDate = new Date(groups[groups.length - 1].timestamp)

                    for (let i = 0; i < daysNeeded; i++) {
                        lastDate.setDate(lastDate.getDate() + 1)

                        groups.push({
                            timestamp: lastDate.getTime(),
                            counter: 0,
                            day: lastDate.toLocaleString("default", { day: "2-digit" })
                        })
                    }
                }

                let labels = []
                let counters = []

                for (let i = 0; i < groups.length; i++) {
                    let currentGroupDate = new Date(groups[i].timestamp)
                    let dayName = currentGroupDate.toLocaleString("en-EN", { weekday: "short" })

                    labels.push(dayName)
                    counters.push(groups[i].counter)
                }

                let dummtData = {
                    labels,
                    datasets: [
                        {
                            label: "Pushes",
                            data: counters,
                            backgroundColor: "#368ADE"
                        }
                    ]
                }

                setData(dummtData)

                setLoading(false)
            }
        }
    }, [dataArray])

    return (
        loading ? 
            <>
                <Bar
                    options={options}
                    data={loadingData}
                    height={290}
                    width={580}
                />
            </>
            :
            <Bar
                options={options}
                data={data}
                height={290}
                width={580}
            />
    )
}

export default Graph