import Header from "../../components/Header"
import React, { useState, useEffect } from "react"
import { BiFilter } from "react-icons/bi"
import { getPushRequests, getTickets, getCampaigns, getHistory } from "../../api/database/user";
import { useMediaQuery } from "react-responsive";
import Graph from "./Graph";
import { formatDate } from "../../utils";
import Loading from "../../components/Loading";
import { useContext } from "react";
import { ThemeContext } from "../../contexts/themeContext";
import Footer from "../../components/Footer";
import { AiFillCaretRight, AiFillCaretLeft } from "react-icons/ai"

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import "./Swiper.css"
import { useRef } from "react";

const Statistics = () => {
    const [filterPanel, openFilter] = useState(false);
    const [pushes, setPushes] = useState([]);
    const [backup, setBackup] = useState([])
    const [startedTimer, setTimer] = useState(false)
    const [loading, setLoading] = useState(false)
    const [ticketsLoading, setTicketsLoading] = useState(false)
    const [historyLoading, setHistoryLoading] = useState(false)
    const [tickets, setTickets] = useState([])
    const [campaign, setCampaign] = useState({
        name: ""
    })
    const [history, setHistory] = useState([])
    const [currentHistoryTitle, setHistoryTitle] = useState("")
    const { theme } = useContext(ThemeContext)

    const prevRef = useRef(null)
    const nextRef = useRef(null)
    const swiperRef = useRef(null)

    const getStatus = (number) => {
        switch (number) {
            case 0:
                return "Pending";
            case 1:
                return "Approved";
            case -1:
                return "Rejected";
            default:
                return "Error";
        }
    }

    const fetchData = async () => {
        setTimer(true)
        let fetched = await getPushRequests()

        if (fetched) {
            let structuredData = []

            for (let i = 0; i < fetched.length; i++) {
                let data = fetched[i]
                let obj = {
                    id: data._id,
                    from: data.username ? data.username : "Anonymous",
                    date: data.date,
                    status: data.state
                }

                structuredData.push(obj)
            }

            setPushes(structuredData)
            setBackup(structuredData)
        }
        setLoading(false)
    }

    const fetchCampaignData = async () => {
        let campaign = await getCampaigns()

        if (campaign) {
            let tickets = await getTickets(campaign.id)
            setTickets(tickets)
        }

        setCampaign(campaign)
        setHistoryTitle(campaign.name)
        setTicketsLoading(false)
    }

    const fetchHistory = async () => {
        let history = await getHistory()

        if (history) {
            setHistory(history.reverse())
        }

        setHistoryLoading(false)
    }

    useEffect(() => {
        if (!startedTimer) {
            setLoading(true)
            setTicketsLoading(true)
            setHistoryLoading(true)
            fetchHistory()
            fetchData()
            fetchCampaignData()
            setInterval(() => {
                setLoading(true)
                setTicketsLoading(true)
                setHistoryLoading(true)
                fetchHistory()
                fetchData()
                fetchCampaignData()
            }, 300000)
        }
    }, [startedTimer])

    return (
        <div className="w-full px-5 md:px-10 pb-10">
            <Header text="Statistics" />

            <div className="w-full mt-[36px] flex flex-col items-center">
                <div className={(theme == "dark" ? "bg-darker border border-dgray" : "bg-lighter border border-lgray") + " relative rounded-[20px] px-3 2sp:px-7 py-1 w-full overflow-hidden " + (loading && "min-h-[700px]")}>
                    <div className="flex items-center justify-between w-full h-fit pr-10 mt-2">
                        <p className={(theme == "dark" ? "text-lgray" : "text-ldark") + " text-xl font-semibold"}>Recent Data Pushes</p>

                        <div
                            className={(theme == "dark" ? "bg-dark border border-darker" : "bg-dwhite border border-lgray") + " relative w-fit h-fit flex items-center px-3 py-1 cursor-pointer " + (filterPanel ? "rounded-t-[8px]" : "rounded-[8px]")}
                            onClick={() => openFilter(!filterPanel)}
                        >
                            <p className={(theme == "dark" ? "text-lgray" : "text-ldark") + " hidden xxs:flex text-xl font-semibold"}>Filter</p>
                            <BiFilter
                                color={theme == "dark" ? "#CBCBCB" : "#6b6c6d"}
                                size={30}
                                className={"xxs:ml-3 transition-all " + (filterPanel && "rotate-180")}
                            />
                            <FiltersBox appear={filterPanel} arrayToApply={backup} apply={setPushes} current={pushes} />
                        </div>
                    </div>

                    <div className={"w-full grid grid-cols-1 gap-3 mt-3 max-h-[600px] overflow-y-scroll pr-4 pb-5 " + (pushes.length > 0 ? (theme == "dark" ? "scrollbar" : "scrollbarlight") : "noscroll")}>
                        {
                            pushes.length > 0 ?
                                <>
                                    <div className="col-span-1 grid grid-cols-2 xs:grid-cols-3 2sp:grid-cols-4 sp:grid-cols-5 gap-5">
                                        <TableText text="From" classes="flex" />
                                        <TableText text="Date" classes="hidden xs:flex" />
                                        <TableText text="ID" classes="hidden 2sp:flex" />
                                        <TableText text="Type" classes="hidden sp:flex" />
                                        <TableText text="Status" classes="flex" />
                                    </div>
                                    {
                                        pushes.map((obj, index) => (
                                            <TableElement from={obj.from} date={formatDate(obj.date)} id={obj.id} type="Request" status={getStatus(obj.status)} key={index} />
                                        ))
                                    }
                                </>
                                :
                                !loading ?
                                    <div className="col-span-1 text-center font-semibold text-neutral-300 text-lg">
                                        No results were found
                                    </div>
                                    :
                                    <></>
                        }
                    </div>
                    <Loading
                        loading={loading}
                        minHeight={700}
                    />
                </div>

                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-5 mt-10">
                    <div className={(theme == "dark" ? "bg-darker bg-opacity-20 border border-dgray" : "bg-lighter border border-lgray") + " col-span-1 px-5 pt-1 pb-4 rounded-[20px] flex items-center flex-col justify-around"}>
                        <div className="relative w-full max-w-[650px] min-h-[312px] flex overflow-x-scroll noscroll">
                            <Graph dataArray={backup} />
                        </div>

                        <div className="w-full max-w-[500px] flex flex-col items-center mt-2">
                            <p className={(theme == "dark" ? "text-lgray" : "text-ldark") + " text-xl font-semibold"}>Total Data Pushes</p>

                            <div className="w-full mt-3">
                                <div className={(theme == "dark" ? "bg-dark border border-darker" : "bg-dwhite border border-lgray") + " flex flex-col items-center xxs:grid grid-cols-2 gap-5 rounded-[15px] py-2"}>
                                    <TableText text="Total pushes" classes="flex" />
                                    <TableText text={backup.length} classes="flex" />
                                </div>

                                <div className={(theme == "dark" ? "bg-dark border border-darker" : "bg-dwhite border border-lgray") + " flex flex-col items-center xxs:grid grid-cols-2 gap-5 rounded-[15px] py-2 mt-2"}>
                                    <TableText text="Unique IDs" classes="flex" />
                                    <TableText text={"All"} classes="flex" />
                                </div>

                                <div className={(theme == "dark" ? "bg-dark border border-darker" : "bg-dwhite border border-lgray") + " flex flex-col items-center xxs:grid grid-cols-2 gap-5 rounded-[15px] py-2 mt-2"}>
                                    <TableText text="Total Approved" classes="flex" />
                                    <TableText text={backup.filter((obj) => obj.status == 1).length} classes="flex" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={(theme == "dark" ? "bg-darker bg-opacity-20 border border-dgray" : "bg-lighter border border-lgray") + " relative col-span-1 h-full rounded-[20px] px-5 py-7 min-h-[530px] grid grid-cols-1 gap-5"}>
                        <div className="w-full col-span-1 h-[250px] overflow-hidden">
                            <div className={(theme == "dark" ? "text-lgray" : "text-ldark") + " w-full text-center text-xl font-semibold"}>
                                {campaign.name}
                            </div>

                            <div className={"w-full max-h-[220px] overflow-y-scroll noscroll relative"}>
                                {
                                    tickets.length > 0 ?
                                        <div>
                                            <div className="pb-3 w-full grid grid-cols-2 xxs:grid-cols-3 gap-2 mt-5">
                                                <div className="col-span-1"><TicketText text="Username" /></div>
                                                <div className="col-span-1"><TicketText text="ID" /></div>
                                                <div className="col-span-1"><TicketText text="Date" classes="hidden xxs:block" /></div>
                                            </div>
                                            {
                                                tickets.map((ticket, index) => (
                                                    <Ticket ticket={ticket} key={index} />
                                                ))
                                            }
                                        </div>
                                        :
                                        <p className={(theme == "dark" ? "text-neutral-400" : "text-ldark") + " text-lg w-full text-center mt-5"}>There aren't tickets for this campaign</p>
                                }

                                <div className="absolute w-full h-[250px] top-0 left-0">
                                    <div className="w-full h-full relative">
                                        <Loading
                                            loading={ticketsLoading}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-full col-span-1 h-[250px] overflow-hidden">
                            <div className={(theme == "dark" ? "text-lgray" : "text-ldark") + " w-full flex items-center justify-around text-xl font-semibold"}>
                                <div
                                    className="image-swiper-button-prev cursor-pointer min-w-[20px]"
                                    onClick={() => {
                                        swiperRef.current.swiper.slidePrev()
                                    }}
                                >
                                    <div ref={prevRef} className="swiper-button-disabled">
                                        <AiFillCaretLeft
                                            color={theme == "dark" ? "#CBCBCB" : "#6b6c6d"}
                                            size={20}
                                        />
                                    </div>
                                </div>

                                {currentHistoryTitle}

                                <div
                                    className="image-swiper-button-next cursor-pointer min-w-[20px]"
                                    onClick={() => {
                                        swiperRef.current.swiper.slideNext()
                                    }}
                                >
                                    <div ref={nextRef}>
                                        <AiFillCaretRight
                                            color={theme == "dark" ? "#CBCBCB" : "#6b6c6d"}
                                            size={20}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={"w-full max-h-[220px] overflow-y-scroll noscroll relative"}>
                                <Swiper
                                    navigation={{
                                        disabledClass: "swiper-button-disabled"
                                    }}
                                    spaceBetween={30}
                                    modules={[Navigation]}
                                    draggable={true}
                                    grabCursor={true}
                                    //initialSlide={history.length - 1}
                                    className="mySwiper"
                                    onSlideChange={(swiper) => {
                                        /*console.log("INDEX DEL MIO SWIPER =>", swiper.realIndex)
                                        console.log("SWIPER LENGTH =>", swiper.slides.length)
                                        //if(swiper.realIndex == 0) prevRef.current.
                                        console.log("CLASSNAME =>", prevRef.current.classList)*/

                                        if (swiper.realIndex == 0) prevRef.current.classList.add("swiper-button-disabled")
                                        else prevRef.current.classList.remove("swiper-button-disabled")

                                        if (swiper.realIndex + 1 == swiper.slides.length) {
                                            nextRef.current.classList.add("swiper-button-disabled")
                                            setHistoryTitle(campaign.name)
                                        }
                                        else {
                                            nextRef.current.classList.remove("swiper-button-disabled")
                                            setHistoryTitle(history[swiper.realIndex].name)
                                        }
                                    }}
                                    onSlidesLengthChange={(swiper) =>{
                                        /*console.log("SWIPER SLIDES LENGTH", swiper.slides.length)
                                        console.log("HISTORY LENGTH =>", history.length)*/
                                        if(swiper.slides.length - 1 == history.length){
                                            setTimeout(() =>{
                                                swiper.slideTo(swiper.slides.length - 1)
                                            }, 100)
                                        }
                                    }}
                                    ref={swiperRef}
                                >
                                    {
                                        history.map((historyCamp, index) => (
                                            <SwiperSlide key={index}>
                                                <div className="w-full h-full overflow-scroll noscroll">
                                                    <div className="pb-3 w-full grid grid-cols-2 xxs:grid-cols-3 gap-2 mt-5">
                                                        <div className="col-span-1"><TicketText text="Username" /></div>
                                                        <div className="col-span-1"><TicketText text="ID" /></div>
                                                        <div className="col-span-1"><TicketText text="Date" classes="hidden xxs:block" /></div>
                                                    </div>
                                                    {
                                                        swiperRef.current.swiper.realIndex + 1 != swiperRef.current.swiper.slides.length &&
                                                        historyCamp.winners.map((winner, index) => (
                                                            <Ticket ticket={winner} key={index} />
                                                        ))
                                                    }
                                                </div>
                                            </SwiperSlide>
                                        ))
                                    }
                                    <SwiperSlide style={{
                                        maxHeight: 120
                                    }}>
                                        <p className={(theme == "dark" ? "text-neutral-400" : "text-ldark") + " text-lg w-full text-center h-full flex items-center justify-center max-w-[400px] mt-[50px]"} id="DIOMEDE">
                                            Winners not currently picked for selcted {campaign.name} event, scroll left to view past winners
                                        </p>
                                    </SwiperSlide>
                                </Swiper>
                                
                                {
                                    historyLoading &&
                                    <div className="absolute w-full h-[250px] top-0 left-0 z-50">
                                        <div className="w-full h-full relative">
                                            <Loading
                                                loading={historyLoading}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

const Ticket = ({
    ticket = {}
}) => {
    const { theme } = useContext(ThemeContext)
    const [username, setUsername] = useState("...")
    const [date, setDate] = useState("...")

    const getUsername = () => {
        setUsername(ticket.username ? ticket.username : ticket.tg_username)
    }

    const getDate = () => {
        let ticketDate = new Date(ticket.timestamp)
        let getYear = Number(ticketDate.toLocaleString("default", { year: "numeric" }))
        let getMonth = Number(ticketDate.toLocaleString("default", { month: "2-digit" }))
        let getDay = Number(ticketDate.toLocaleString("default", { day: "2-digit" }))

        setDate(getYear + "-" + getMonth + "-" + getDay)
    }

    useEffect(() => {
        getUsername()
        getDate()
    }, [])

    return (
        <div className={(theme == "dark" ? "bg-dark border border-darker" : "bg-dwhite border border-ldark") + " w-full grid grid-cols-2 xxs:grid-cols-3 gap-2 rounded-[15px] py-2"}>
            <TicketText text={username} />
            <TicketText text={ticket.id} />
            <TicketText text={date} classes="hidden xxs:block" />
        </div>
    )
}

const TicketText = ({
    text = "",
    classes = ""
}) => {
    const { theme } = useContext(ThemeContext)

    return (
        <p className={(theme == "dark" ? "text-neutral-400" : "text-ldark") + " col-span-1 text-center font-semibold " + classes}>{text}</p>
    )
}

const TableText = ({
    text = "",
    classes = ""
}) => {
    const { theme } = useContext(ThemeContext)

    return <p className={(theme == "dark" ? "text-neutral-400" : "text-ldark") + " col-span-1 items-center justify-center text-lg font-semibold " + classes}>{text}</p>
}

const TableElement = ({
    from = "",
    date = "",
    id = "",
    type = "",
    status = ""
}) => {
    const fromQuery = useMediaQuery({ query: '(min-width: 462px)' })
    const { theme } = useContext(ThemeContext)

    return (
        <div className={(theme == "dark" ? "bg-dark border border-darker" : "bg-dwhite border border-ldark") + " col-span-1 grid grid-cols-2 xs:grid-cols-3 2sp:grid-cols-4 sp:grid-cols-5 gap-5 rounded-[15px] py-2"}>
            <TableText text={fromQuery ? from : from.substring(0, 5) + "..."} classes="flex" />
            <TableText text={date} classes="hidden xs:flex" />
            <TableText text={id.substring(0, 8) + "..."} classes="hidden 2sp:flex" />
            <TableText text={type} classes="hidden sp:flex" />
            <StatusBox status={status} classes="flex" />
        </div>
    )
}

const StatusBox = ({
    status = ""
}) => {
    return (
        <div className="col-span-1 flex justify-center">
            <div className={`
                w-[90px] as:w-[105px] h-fit 
                py-1
                rounded-[20px] 
                text-neutral-100 as:text-lg text-center font-semibold
                ${status == "Approved" ?
                    "bg-lgreen"
                    :
                    status == "Pending" ?
                        "bg-lpurple"
                        :
                        "bg-lred"
                }
            `}>
                {status}
            </div>
        </div>
    )
}

const FiltersBox = ({
    arrayToApply = [],
    apply = () => { },
    appear = false,
    current = []
}) => {
    const { theme } = useContext(ThemeContext)

    const applyAsc = () => {
        let aux = [...current]
        apply(aux.sort((a, b) => { return a.date > b.date ? 1 : -1 }))
    }

    const applyDesc = () => {
        let aux = [...current]
        apply(aux.sort((a, b) => { return a.date < b.date ? 1 : -1 }))
    }

    const applyPending = () => {
        let filtered = arrayToApply.filter((obj) => obj.status == 0)
        apply(filtered)
    }

    const applyApproved = () => {
        let filtered = arrayToApply.filter((obj) => obj.status == 1)
        apply(filtered)
    }

    const applyRejected = () => {
        let filtered = arrayToApply.filter((obj) => obj.status == -1)
        apply(filtered)
    }

    const reset = () => apply(arrayToApply)

    return (
        <div className={(theme == "dark" ? "bg-dark border border-darker" : "bg-dwhite border border-lgray") + " top-[38px] right-[-1px] absolute w-[200px] h-fit rounded-b-[8px] rounded-tl-[8px] overflow-hidden " + (appear ? "block" : "hidden")}>
            <Filter text="Ored Asc" action={applyAsc} />
            <Filter text="Ored Desc" action={applyDesc} />
            <Filter text="Only Pending" action={applyPending} />
            <Filter text="Only Approved" action={applyApproved} />
            <Filter text="Only Rejected" action={applyRejected} />
            <Filter text="Reset" action={reset} />
        </div>
    )
}

const Filter = ({
    text = "",
    action = () => { }
}) => {
    const { theme } = useContext(ThemeContext)

    return (
        <div
            className={(theme == "dark" ? "text-neutral-300 hover:bg-lgray hover:bg-opacity-10" : "text-ldark hover:bg-ldark hover:bg-opacity-10") + " w-full text-center py-2 transition-colors text-md"}
            onClick={action}
        >
            {text}
        </div>
    )
}

export default Statistics