import { userClient } from "./connections";
import { SHA3 } from "sha3"
import Cookies from 'universal-cookie';

export async function askGPT(question) {
    let req = await fetch(`${process.env.REACT_APP_API_URL}/user/chatgpt`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            question: question
        })
    })
    let res = await req.json()

    if (res.failed) return null
    else return res.result
}

export async function search(question) {
    let req = await fetch(`${process.env.REACT_APP_API_URL}/user/search`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            question: question
        })
    })

    let res = await req.json()

    let data = res.result
    let structuredData = []

    if (data) {
        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                let obj = {
                    text: data[i].answer,
                    originalQuestion: data[i].question,
                    date: data[i].date,
                    from: data[i].from,
                    id: data[i].id ? data[i].id : data[i]._id,
                }

                structuredData.push(obj)
            }

            return structuredData
        }
        else return [{
            text: data.answer,
            originalQuestion: data.question,
            date: data.date,
            from: data.from,
            id: data.id ? data.id : data._id,
        }]
    }
    else return false
}

export async function pushRequest(question, answer, username) {
    let req = await fetch(`${process.env.REACT_APP_API_URL}/user/request`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            question: question,
            answer: answer,
            username: username
        })
    })
    let res = await req.json()

    return res
}

export async function assignTicket(username, email) {
    let req = await fetch(`${process.env.REACT_APP_API_URL}/user/ticket/assign`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            username: username,
            email: email
        })
    })
    let res = await req.json()

    return res
}

export async function register(email, username, password) {
    try {
        const cookies = new Cookies();
        const hash = new SHA3(512)
        const hashedPass = hash.update(password).digest("hex")

        let req = await fetch(`${process.env.REACT_APP_API_URL}/user/register`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                username: username,
                password: hashedPass
            })
        })
        let res = await req.json()

        if(!res.failed && res.failed != null){
            cookies.set("session", res.user.authToken)
        }

        return res
    } catch (err) {
        console.log(err)
        return false
    }
}

export async function login(email, username, password) {
    try {
        const cookies = new Cookies();
        const hash = new SHA3(512)
        const hashedPass = hash.update(password).digest("hex")

        let req = await fetch(`${process.env.REACT_APP_API_URL}/user/login`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                username: username,
                password: hashedPass
            })
        })
        let res = await req.json()

        if(!res.failed && res.failed != null){
            cookies.set("session", res.user.authToken)
        }

        return res
    } catch (err) {
        console.log(err)
        return false
    }
}

export async function authLogin() {
    const cookies = new Cookies();
    const currentCookie = cookies.get('session')

    let req = await fetch(`${process.env.REACT_APP_API_URL}/user/authLogin`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            token: currentCookie
        })
    })

    let res = await req.json()
    
    return res.user
}

export async function vote(username, id, status, password, email, loggedIn) {
    try {
        const hash = new SHA3(512)
        const hashedPass = hash.update(password).digest("hex")

        let req = await fetch(`${process.env.REACT_APP_API_URL}/user/vote`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                choice: status,
                username: username,
                email: email,
                id: id,
                password: hashedPass,
                loggedVote: loggedIn
            })
        })
        let res = await req.json()

        return res
    } catch (err) {
        console.log(err)
        return false
    }
}

export async function getQueryVotes(queryId) {
    try {
        let req = await fetch(`${process.env.REACT_APP_API_URL}/user/getVotes`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: queryId
            })
        })
        let res = await req.json()

        return res.votes
    } catch (err) {
        console.log(err)
        return false
    }
}

export async function updateProfile(oldEmail, oldUsername, password, email, username, image, newPass) {
    try {
        const hash = new SHA3(512)
        const hashedPass = hash.update(password).digest("hex")
        hash.reset()
        const hashedNewPass = hash.update(newPass).digest("hex")

        let req = await fetch(`${process.env.REACT_APP_API_URL}/user/update`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                username: username,
                image: image,
                oldEmail: oldEmail,
                oldUsername: oldUsername,
                password: hashedPass,
                newPassword: hashedNewPass
            })
        })
        let res = await req.json()

        return res
    } catch (err) {
        console.log(err)
        return false
    }
}

export async function getPushRequests() {
    try {
        let req = await fetch(`${process.env.REACT_APP_API_URL}/user/requests`)
        let res = await req.json()
        let docs = res.docs

        const currentTimestamp = new Date(Date.now());
        let getYear = Number(currentTimestamp.toLocaleString("default", { year: "numeric" }))
        let getMonth = Number(currentTimestamp.toLocaleString("default", { month: "2-digit" }))
        let getDay = Number(currentTimestamp.toLocaleString("default", { day: "2-digit" }))

        let dateToTakeInConsideration = new Date(getYear, getMonth - 1, getDay, 23, 59, 59)
        let timeStampToTakeInConsideratoin = dateToTakeInConsideration.getTime()

        let filteredPushes = []

        for (let i = 0; i < docs.length; i++) {
            let data = docs[i]
            let difference = timeStampToTakeInConsideratoin - data.date
            let daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);

            if (daysDifference < 7) filteredPushes.push(data)
        }

        return res.failed ? [] : filteredPushes

    } catch (err) {
        console.log(err)
        return false
    }
}

export async function getTickets(id) {
    let req = await fetch(`${process.env.REACT_APP_API_URL}/user/tickets/${id}`)
    let res = await req.json()

    return res.failed ? [] : res.tickets
}

export async function getCampaigns() {
    let req = await fetch(`${process.env.REACT_APP_API_URL}/user/campaigns`)
    let res = await req.json()

    return res.failed ? null : res.campaign
}

export async function getHistory() {
    let req = await fetch(`${process.env.REACT_APP_API_URL}/user/history`)
    let res = await req.json()

    return res.failed ? null : res.history
}

export async function getPartnerships() {
    try {
        let entries = await userClient.getEntries()

        let filteredObjs = []
        for (let i = 0; i < entries.items.length; i++) {
            let entry = entries.items[i]
            if (entry.sys.contentType.sys.id == 'partnerships') {
                let imageUrl = entry.fields.profileImage.fields.file.url
                let entryId = entry.sys.id
                let obj = {
                    name: entry.fields.companyName,
                    sector: entry.fields.sector,
                    url: imageUrl,
                    description: entry.fields.description1,
                    id: entryId
                }
                filteredObjs.push(obj)
            }
        }

        return filteredObjs
    } catch (err) {
        console.log(err)
        return []
    }
}

export async function getTargets() {
    try {
        let entries = await userClient.getEntries()

        let filteredObjs = []
        for (let i = 0; i < entries.items.length; i++) {
            let entry = entries.items[i]
            if (entry.sys.contentType.sys.id == 'target') {
                let obj = {
                    name: entry.fields.targetName,
                    max: entry.fields.max,
                    min: entry.fields.min,
                    plcMax: entry.fields.placeholderMax,
                    plcMin: entry.fields.placeholderMin
                }
                filteredObjs.push(obj)
            }
        }

        return filteredObjs
    } catch (err) {
        console.log(err)
        return []
    }
}

export async function getNotification(id) {
    const entry = await userClient.getEntry(id)

    return {
        title: entry.fields.title,
        description: entry.fields.description,
        fetched: true
    }
}

export async function getEntryById(id) {
    try {
        let entry = await userClient.getEntry(id)

        return entry
    } catch (err) {
        console.log(err)
        return null
    }
}