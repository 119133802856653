import { useContext } from 'react';
import { FrameContext } from '../contexts/frameContext';
import Dashboard from '../frames/Dashboard';
import Partners from '../frames/Partners';
import Settings from '../frames/Settings';
import Statistics from '../frames/Statistics/Statistics';
import Trade from '../frames/Trade';
import VirtualStore from '../frames/VirtualStore';
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import PartnerPage from '../frames/PartnerPage';
import { PartnerProvider } from '../contexts/partnershipContext';

const Proxy = () => {
    const { frame } = useContext(FrameContext)

    return (
        <>
            {frame == "dashboard" && <Dashboard />}
            {
                frame == "partners" &&
                <PartnerProvider>
                    <Routes>
                        <Route exact path="/" element={<Partners />} />
                        <Route path="/:partner" element={<PartnerPage />} />
                    </Routes>
                </PartnerProvider>
            }
            {frame == "login" && <Settings />}
            {frame == "register" && <Settings />}
            {frame == "virtual" && <VirtualStore />}
            {frame == "stats" && <Statistics />}
            {frame == "trade" && <Trade />}
        </>
    )
}

export default Proxy