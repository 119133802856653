import { ThemeContext } from "../contexts/themeContext"
import { useContext } from "react"

const ThemeSwitch = ({ classes = "" }) =>{
    const { theme, setTheme } = useContext(ThemeContext)

    return(
        <div 
            className={`w-[35px] h-fit bg-dark border border-dgray rounded-full bg-opacity-20 mr-2 cursor-pointer flex ` + classes}
            onClick={() => { 
                if(theme == "dark") setTheme("light")
                else setTheme("dark")
            }}
        >
            <div className={`h-[15px] w-[15px] rounded-full bg-lblue ${theme == "dark" ? "ml-[18px]" : "ml-0"} transition-all`}>

            </div>
        </div>
    )
}

export default ThemeSwitch