import { useRef } from "react"
import { useContext } from "react"
import { useState } from "react"
import { AiFillEye, AiTwotoneEyeInvisible } from "react-icons/ai"
import { IoCloseSharp } from "react-icons/io5"
import { ThemeContext } from "../contexts/themeContext"

const Popup = ({
    visible = false,
    execute = () => { },
    closePopup = () => { }
}) => {
    const [passVisible, setVisible] = useState(false)
    const passRef = useRef(null)
    const { theme } = useContext(ThemeContext)

    return (
        visible &&
        <div className="absolute left-0 top-0 w-full h-full bg-darker bg-opacity-60 flex items-center justify-center rounded-[20px] px-2">
            <div className={(theme == "dark" ? "bg-gradient-to-bl from-dark to-darker border border-dgray" : "bg-lighter border border-lgray") + " relative w-full max-w-[370px] h-fit py-5 px-2 rounded-xl flex flex-col items-center justify-center"}>
                <h1 className={(theme == "dark" ? "text-neutral-300" : "text-ldark") + " text-lg font-semibold"}>Confirm action</h1>

                <div className="relative w-full mt-2">
                    <input
                        ref={passRef}
                        type={passVisible ? "text" : "password"}
                        placeholder="Password"
                        className={(theme == "dark" ? "bg-dark bg-opacity-20 border border-ldark text-lgray" : "bg-dwhite border border-lgray text-ldark") + " px-2 py-1 text-lg font-semibold mt-2 w-full rounded-lg appearence-none focus:outline-0"}
                    />
                    {
                        !passVisible ?
                            <AiFillEye
                                color={theme == "dark" ? "#CBCBCB" : "#6b6c6d"} 
                                size={25}
                                className="absolute right-2 bottom-[6px] cursor-pointer"
                                onClick={() => setVisible(true)}
                            />
                            :
                            <AiTwotoneEyeInvisible
                                color={theme == "dark" ? "#CBCBCB" : "#6b6c6d"} 
                                size={25}
                                className="absolute right-2 bottom-[6px] cursor-pointer"
                                onClick={() => setVisible(false)}
                            />
                    }
                </div>

                <div
                    className={(theme == "dark" ? "text-neutral-100" : "text-dark") + " w-fit px-3 py-2 text-center bg-dark bg-opacity-20 hover:bg-lblue hover:bg-opacity-100 transition-colors rounded-xl mt-4 cursor-pointer border border-ldark font-semibold"}
                    onClick={() => {
                        if(passRef.current.value != "") execute(passRef.current.value)
                    }}
                >
                    Confirm
                </div>

                <IoCloseSharp
                    size={20}
                    color={theme == "dark" ? "#CBCBCB" : "#6b6c6d"} 
                    className="absolute top-2 right-2 cursor-pointer"
                    onClick={() => closePopup()}
                />
            </div>
        </div>
    )
}

export default Popup