import Header from "../components/Header"
import Layout from "../components/Layout"
import { FaWallet } from "react-icons/fa"
import { useSelector, useDispatch } from "react-redux"
import { connect, updateAccount } from "../redux/blockchain/blockchainActions";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { ADDRESS } from "../redux/contractInfo/contract";
import abi from "../redux/contractInfo/abi.json"
import { BarLoader } from "react-spinners";
import { BsCheckLg } from "react-icons/bs";
import { ImCross } from "react-icons/im"
import { useContext } from "react";
import { ThemeContext } from "../contexts/themeContext";
import Footer from "../components/Footer";

const VirtualStore = () => {
    const dispatch = useDispatch()
    const blockchain = useSelector((state) => state)
    const [loading, setLoading] = useState(false)
    const [eligible, setEligible] = useState(false)
    const { theme } = useContext(ThemeContext)

    const getAccounts = async () => {
        const { ethereum } = window;

        let accountsConnected = await ethereum.request({ method: 'eth_accounts' })
        return accountsConnected;
    }

    const setConnectedAccount = async () => {
        const { ethereum } = window;
        let accounts = await getAccounts()
        if (accounts.length > 0) {
            dispatch(updateAccount(accounts[0]))

            ethereum.on("accountsChanged", (accounts) => {
                dispatch(updateAccount(accounts[0]));
            });
        }
    }

    const fetchOwnership = async () => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(ADDRESS, abi, signer);

        const balance = await contract.balanceOf(blockchain.account)

        if (Number(balance.toString()) > 0) setEligible(true)
        setLoading(false)
    }

    useEffect(() => {
        setConnectedAccount()
    }, [])

    useEffect(() => {
        if(blockchain.account) {
            setLoading(true)
            fetchOwnership()
        }
    }, [blockchain.account])

    return (
        <Layout>
            <Header text="VirtualStore" />
            <div className="pr-5 xxs:pr-10 mt-[86px] flex justify-center">
                <div className={(theme == "dark" ? "bg-gradient-to-bl from-dark to-darker border border-dgray" : "bg-lighter border border-lgray") + " rounded-[20px] w-full max-w-[800px] py-20 px-5 flex items-center justify-center flex-col min-h-[380px]"}>
                    {
                        blockchain.account ?
                            <>
                                {
                                    loading ?
                                        <BarLoader
                                            color="#368ADE"
                                            loading={loading}
                                            width={200}
                                        />
                                        :
                                        eligible ?
                                            <>
                                                <h1 className={(theme == "dark" ? "text-lgray" : "text-ldark") + " text-4xl font-semibold text-center"}>Connection Successful</h1>
                                                <BsCheckLg
                                                    size={70}
                                                    className="mt-7"
                                                    color="#368ADE"
                                                />
                                                <p className={(theme == "dark" ? "text-lgray" : "text-ldark") + " text-xl font-semibold text-center w-full max-w-[400px] mt-5"}>
                                                    VirtualStore coming soon!
                                                </p>
                                            </>
                                            :
                                            <>
                                                <h1 className={(theme == "dark" ? "text-lgray" : "text-ldark") + " text-4xl font-semibold text-center"}>Connection Rejected</h1>
                                                <ImCross
                                                    size={70}
                                                    className="mt-7"
                                                    color="#D04530"
                                                />
                                                <p className={(theme == "dark" ? "text-lgray" : "text-ldark") + " text-lg font-semibold text-center w-full max-w-[600px] mt-6"}>
                                                    {"You're not eligible to connect to the VirtualStore, requirement is to own a [NAME OF NFT]. "}
                                                    <span>
                                                        <a 
                                                            href="https://youtube.com/"
                                                            className="underline text-ldark hover:text-lgray transition-colors"
                                                        > 
                                                            Click here to mint one
                                                        </a>
                                                    </span>
                                                </p>
                                            </>
                                }
                            </>
                            :
                            <>
                                <h1 className={(theme == "dark" ? "text-lgray" : "text-ldark") + " text-4xl font-semibold text-center"}>Check Wallet</h1>
                                <div className="w-fit h-fit"
                                    onClick={() => {
                                        dispatch(connect())
                                    }}
                                >
                                    <FaWallet
                                        size={100}
                                        className="mt-7 cursor-pointer hover:scale-110"
                                        color="#6b6c6d"
                                    />
                                </div>
                                <p className={(theme == "dark" ? "text-lgray" : "text-ldark") + " text-lg font-semibold text-center w-full max-w-[400px] mt-5"}>
                                    Click the icon to connect with metamask and check if you're eligible to the Virtual Store!
                                </p>
                            </>
                    }
                </div>
            </div>
            <Footer/>
        </Layout>
    )
}

export default VirtualStore