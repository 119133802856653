import Header from "../components/Header"
import { AiFillCaretRight } from "react-icons/ai"
import { FrameContext } from "../contexts/frameContext"
import { UsernameContext } from "../contexts/userContext"
import { useContext, useState, useRef } from "react"
import { useMediaQuery } from "react-responsive"
import Layout from "../components/Layout"
import { useEffect } from "react"
import ChoicesMenu from "../components/ChoicesMenu"
import { askGPT, pushRequest, search, vote, getQueryVotes, getEntryById, assignTicket, authLogin, getCampaigns, getTickets } from "../api/database/user"
import { FaChevronDown } from "react-icons/fa"
import { RiDeleteBin7Line } from "react-icons/ri"
import { formatDate } from "../utils";
import Popup from "../components/Popup"
import { toast } from "react-toastify"
import openai_dark from "../media/openai_dark.png"
import openai_light from "../media/openai_light.png"
import { ThemeContext } from "../contexts/themeContext"
import { useNavigate } from "react-router-dom"
import Footer from "../components/Footer";
import { BiArchiveIn } from "react-icons/bi"

const Dashboard = () => {
    const online = true
    const chatGPTRef = useRef(null)
    const searchRef = useRef(null)
    const chatGPTBoxRef = useRef(null)
    const searchBoxRef = useRef(null)
    const { setFrame } = useContext(FrameContext)
    const { username, email, loggedIn } = useContext(UsernameContext)
    const { theme } = useContext(ThemeContext)
    const tagsQuery = useMediaQuery({ query: '(min-width: 1836px)' })
    const tagsQuery2 = useMediaQuery({ query: '(min-width: 524px)' })
    const partnerQuery = useMediaQuery({ query: '(min-width: 640px)' })
    const partnerQuery2 = useMediaQuery({ query: '(min-width: 391px)' })

    const [chatCache, setChatCache] = useState([])
    const [archAICache, setAAICache] = useState([])
    const [loadingChatGPT, setLoadingChatGPT] = useState(false)
    const [loadingArchAI, setLoadingArchAI] = useState(false)
    const [searched, setSearched] = useState(false)
    const [choice, setChoice] = useState(0)
    const [currentID, setCurrentID] = useState("")
    const [popupOpened, openPopup] = useState(false)
    const [orderDateAsc, setDateAsc] = useState(false)
    const [orderVotesAsc, setVotesAsc] = useState(true)

    const askChatGPT = async (question) => {
        let answer = await askGPT(question)

        if (answer) {
            setChatCache([
                ...chatCache,
                {
                    from: "service",
                    text: answer,
                    originalQuestion: question,
                    service: "gpt"
                }
            ])
        }
        else setChatCache([])

        setLoadingChatGPT(false)
    }

    const searchInArchive = async (question) => {
        let data = await search(question)

        if (data) {
            let structuredData = []

            for (let i = 0; i < data.length; i++) {
                let votes = await getQueryVotes(data[i].id)
                let obj = {
                    id: data[i].id,
                    originalQuestion: data[i].originalQuestion,
                    date: data[i].date,
                    from: data[i].from,
                    text: data[i].text,
                    voteData: votes
                }

                structuredData.push(obj)
            }

            setAAICache(structuredData)
        }
        else setSearched(true)

        setLoadingArchAI(false)
    }

    const sendVote = async (password) => {
        const toastId = toast.loading("Processing your vote")
        let res = await vote(username, currentID, choice == 1, password, email)

        if (res.failed) {
            toast.update(toastId, {
                render: res.error,
                type: "error",
                isLoading: false,
                autoClose: 2000
            })
        }
        else {
            toast.update(toastId, {
                render: "Successfully voted",
                type: "success",
                isLoading: false,
                autoClose: 2000
            })
        }
    }

    const orderDate = () => {
        chatCache.sort((a, b) => { return a.voteData.lenght > b.voteData.lenght ? 1 : -1 })
    }

    const orderVotes = () => {
        chatCache.sort((a, b) => { return a.date > b.date ? 1 : -1 })
    }

    useEffect(() => {
        if (chatCache.length > 0 && chatGPTRef.current.value != "") {
            askChatGPT(chatGPTRef.current.value)
            chatGPTRef.current.value = ""
        }
        chatGPTBoxRef.current.scrollTop = chatGPTBoxRef.current.scrollHeight
    }, [chatCache])

    useEffect(() => {
        if (loadingArchAI && searchRef.current.value != "") {
            setAAICache([])
            setSearched(false)
            searchInArchive(searchRef.current.value)
            searchRef.current.value = ""
        }
    }, [loadingArchAI])

    useEffect(() => {
        if (orderDateAsc) orderDate()
        else orderVotes()
    }, [archAICache])

    return (
        <Layout>
            <Header text="Dashboard" />
            <div className="pr-5 xxs:pr-10 mt-[36px] 2xl:grid grid-cols-3 gap-5">
                <div className="col-span-2 2xl:pr-10">
                    <div className="flex items-center justify-between">
                        <p className={(theme == "dark" ? "text-lgray" : "text-ldark") + " font-semibold text-lg"}>Partners</p>
                        <p
                            className={(theme == "dark" ? "text-lgray hover:text-white" : "text-ldark hover:text-dark") + " font-semibold text-lg transition-colors flex items-center cursor-pointer"}
                            onClick={() => setFrame("partners")}
                        >
                            View all
                            <AiFillCaretRight color={theme == "dark" ? "#CBCBCB" : "#6b6c6d"} />
                        </p>
                    </div>

                    <div className="w-full flex justify-between mt-2">
                        <PartnerBox id="5u5mcZPMIbNNWgcgtvIN8j" />
                        {/*partnerQuery && <PartnerBox name="Chainback" bg="partner_box" />*/}
                        {/*partnerQuery2 && <PartnerBox name="Chainback" bg="partner_box" />*/}
                    </div>

                    <div className={(theme == "dark" ? "bg-gradient-to-bl from-dark to-dark border border-dgray" : "bg-lwhite border border-lgray") + " relative w-full h-fit min-h-[420px] rounded-[20px] mt-[40px] p-3 xxs:p-7"}>
                        {
                            !tagsQuery2 &&
                            <div className={`text-white px-5 py-1 ${online ? "bg-lgreen" : "bg-lred"} rounded-full text-lg font-semibold w-fit h-fit ml-2`}>
                                {online ? "Online" : "Offline"}
                            </div>
                        }
                        {
                            !tagsQuery &&
                            <div className={"w-full flex items-center " + (!tagsQuery2 && "mt-2")}>
                                <div
                                    className={(theme == "dark" ? "border border-dgray" : "border border-lgray") + " w-fit h-fit p-2 text-neutral-300 rounded-lg cursor-pointer font-semibold text-center " + (orderVotesAsc ? "bg-lblue text-neutral-200" : (theme == "dark" ? "bg-gradient-to-r from-darker to-darker text-neutral-200" : "bg-gradient-to-r from-lwhite to-dwhite text-dark"))}
                                    onClick={() => {
                                        orderVotes()
                                        setVotesAsc(true)
                                        setDateAsc(false)
                                    }}
                                >
                                    Vote: Ascending
                                </div>


                                <div
                                    className={(theme == "dark" ? "border border-dgray" : "border border-lgray") + " w-fit h-fit p-2 rounded-lg cursor-pointer font-semibold ml-2 text-center " + (orderDateAsc ? "bg-lblue text-neutral-200" : (theme == "dark" ? "bg-gradient-to-r from-darker to-darker text-neutral-200" : "bg-gradient-to-r from-lwhite to-dwhite text-dark"))}
                                    onClick={() => {
                                        orderDate()
                                        setVotesAsc(false)
                                        setDateAsc(true)
                                    }}
                                >
                                    Date: Recent
                                </div>
                                {
                                    tagsQuery2 &&
                                    <div className={`text-white px-5 py-1 ${online ? "bg-lgreen" : "bg-lred"} rounded-full font-semibold w-fit h-fit ml-2`}>
                                        {online ? "Online" : "Offline"}
                                    </div>
                                }
                            </div>
                        }

                        <div className="flex items-center justify-between">
                            <input
                                type={"text"}
                                ref={searchRef}
                                disabled={loadingArchAI}
                                className={`
                                        font-semibold text-xl
                                        px-3 py-2
                                        ${theme == "dark" ? "bg-gradient-to-r from-darker to-darker border border-darker text-lgray" : "bg-gradient-to-r from-lwhite to-dwhite border border-lgray text-ldark"}
                                        rounded-[13px]
                                        w-full ${tagsQuery ? "max-w-[400px]" : "mt-2"}
                                        appearence-none focus:outline-0
                                `}
                                placeholder={loadingArchAI ? "Loading..." : "Archive AI Search..."}
                                onKeyDown={(e) => {
                                    if (e.key == "Enter" && searchRef.current.value != "") {
                                        setLoadingArchAI(true)
                                    }
                                }}
                            />
                            <div
                                className={(theme == "dark" ? "bg-darker border border-darker text-white" : "bg-gradient-to-r from-lwhite to-dwhite border border-lgray text-ldark") + (!tagsQuery ? " ml-2 mt-2" : "") +  " px-3 py-2 rounded-lg h-fit font-semibold cursor-pointer"}
                                onClick={() => {
                                    if (searchRef.current.value != "" && !loadingArchAI) {
                                        setLoadingArchAI(true)
                                    }
                                }}
                            >
                                Send
                            </div>
                            {
                                tagsQuery &&
                                <>
                                    <div
                                        className={(theme == "dark" ? "border border-dgray" : "border border-lgray") + " w-fit h-fit p-2 rounded-lg border border-ldark cursor-pointer font-semibold text-center " + (orderVotesAsc ? "bg-lblue text-neutral-200" : (theme == "dark" ? "bg-gradient-to-r from-darker to-darker text-neutral-300" : "bg-gradient-to-r from-lwhite to-dwhite text-dark"))}
                                        onClick={() => {
                                            orderVotes()
                                            setVotesAsc(true)
                                            setDateAsc(false)
                                        }}
                                    >
                                        Vote: Ascending
                                    </div>


                                    <div
                                        className={(theme == "dark" ? "border border-dgray" : "border border-lgray") + " w-fit h-fit p-2 rounded-lg border border-ldark cursor-pointer font-semibold text-center " + (orderDateAsc ? "bg-lblue text-neutral-200" : (theme == "dark" ? "bg-gradient-to-r from-darker to-darker text-neutral-300" : "bg-gradient-to-r from-lwhite to-dwhite text-dark"))}
                                        onClick={() => {
                                            orderDate()
                                            setVotesAsc(false)
                                            setDateAsc(true)
                                        }}
                                    >
                                        Date: Recent
                                    </div>

                                    <div className={`text-white px-5 py-1 ${online ? "bg-lgreen" : "bg-lred"} rounded-full font-semibold w-fit h-fit`}>
                                        {online ? "Online" : "Offline"}
                                    </div>
                                </>
                            }
                        </div>

                        <div className="w-full flex items-end justify-between mt-3 pr-3">
                            <p className={(theme == "dark" ? "text-neutral-300" : "text-ldark") + " font-semibold text-lg"}>Results:{archAICache.length == 0 ? searched ? " None" : "" : " " + archAICache.length}</p>
                            <RiDeleteBin7Line
                                size={20}
                                color={theme == "dark" ? "#CBCBCB" : "#6b6c6d"}
                                className="cursor-pointer"
                                onClick={() => {
                                    setAAICache([])
                                    setSearched(false)
                                    setCurrentID("")
                                    setChoice(0)
                                }}
                            />
                        </div>

                        <div className={(theme == "dark" ? "bg-gradient-to-r from-darker to-darker border border-darker" : "bg-gradient-to-r from-lwhite to-dwhite border border-lgray") + " w-full rounded-[15px] h-[300px] mt-2 px-2 as:px-5 overflow-y-scroll noscroll py-5 scroll-smooth"} ref={searchBoxRef}>
                            {
                                archAICache.length > 0 &&
                                <div className="w-full hidden xs:grid grid-cols-6 gap-3">
                                    <p className="text-lg text-ldark font-semibold col-span-2 sm:col-span-1 text-center hidden xs:block">Publisher</p>
                                    <p className="text-lg text-ldark font-semibold col-span-4 sm:col-span-3 2sp:col-span-2 text-center">Prompt Preview</p>
                                    <p className="text-lg text-ldark font-semibold col-span-1 text-center hidden md:block">Date</p>
                                    <p className="text-lg text-ldark font-semibold col-span-1 text-center hidden 2sp:block">Vote</p>
                                </div>
                            }
                            {
                                archAICache.map((msg, index) => (
                                    <SearchResult
                                        prompt={msg.originalQuestion}
                                        answer={msg.text}
                                        username={msg.from}
                                        timestamp={msg.date}
                                        id={msg.id}
                                        handleVote={async (state) => {
                                            setChoice(state)
                                            setCurrentID(msg.id)
                                            if (loggedIn) openPopup(true)
                                            else {
                                                toast("You must log in", {
                                                    type: "warning"
                                                })
                                            }
                                        }}
                                        key={index}
                                        classes={index != 0 && "mt-2"}
                                    />
                                ))
                            }
                            {
                                archAICache.length == 0 && searched &&
                                <p className={(theme == "dark" ? "text-neutral-300" : "text-ldark") + " text-lg text-center mt-5"}>No results found. Use our ChatGPT to find an answer, then push it to our archive</p>
                            }
                            {
                                loadingArchAI &&
                                <>
                                    <LoadingSearchResult />
                                    <LoadingSearchResult classes="mt-2" />
                                </>
                            }
                        </div>
                        <Popup
                            visible={popupOpened}
                            closePopup={() => {
                                openPopup(false)
                                setChoice(0)
                                setCurrentID("")
                            }}
                            execute={(pass) => {
                                if (choice != 0) {
                                    sendVote(pass)
                                    setChoice(0)
                                    setCurrentID("")
                                    openPopup(false)
                                }
                            }}
                        />
                    </div>
                </div>

                <div className={(theme == "dark" ? "bg-gradient-to-bl from-darker to-darker border border-dgray" : "bg-lwhite border border-lgray") + " col-span-1 rounded-[20px] h-full max-h-[878px] mt-5 2xl:mt-0 px-5 py-4 flex flex-col"}>
                    <div className="w-full flex items-center justify-between h-fit">
                        <p className={(theme == "dark" ? "text-white" : "text-dark") + " text-4xl font-semibold"}>ChatGPT</p>
                    </div>

                    <div className="w-full h-[300px] 2xl:h-full mt-5 overflow-y-scroll noscroll scroll-smooth" ref={chatGPTBoxRef}>
                        {
                            chatCache.map((msg, index) => (
                                <Message from={msg.from} content={msg.text} key={index} classes={index != 0 && "mt-2"} service={msg.service} question={msg.originalQuestion} />
                            ))
                        }
                        {loadingChatGPT && <LoadingMessage />}
                    </div>

                    <div className="w-full flex h-fit mt-4 ">
                        <input
                            type={"text"}
                            ref={chatGPTRef}
                            disabled={loadingChatGPT}
                            className={`
                                w-full 
                                font-semibold text-xl
                                px-3 py-2
                                ${theme == "dark" ? "bg-gradient-to-r from-dark to-dark border border-darker text-lgray" : "bg-gradient-to-r from-lwhite to-dwhite border border-lgray text-ldark"}
                                rounded-[13px] 
                                appearence-none focus:outline-0
                            `}
                            placeholder="Type here..."
                            onKeyDown={(e) => {
                                if (e.key == "Enter" && chatGPTRef.current.value != "") {
                                    setLoadingChatGPT(true)
                                    setChatCache([
                                        ...chatCache,
                                        {
                                            from: username,
                                            text: chatGPTRef.current.value
                                        }
                                    ])
                                }
                            }}
                        />

                        <div
                            className={(theme == "dark" ? "bg-gradient-to-r from-dark to-dark border border-darker text-white" : "bg-gradient-to-r from-lwhite to-dwhite border border-lgray text-ldark") + " px-3 py-2 rounded-lg ml-2 h-fit font-semibold cursor-pointer"}
                            onClick={() => {
                                if (chatGPTRef.current.value != "" && !loadingChatGPT) {
                                    setLoadingChatGPT(true)
                                    setChatCache([
                                        ...chatCache,
                                        {
                                            from: username,
                                            text: chatGPTRef.current.value
                                        }
                                    ])
                                }
                            }}
                        >
                            Send
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Layout>
    )
}

const PartnerBox = ({
    id = ""
}) => {
    const { theme } = useContext(ThemeContext)
    const [name, setName] = useState("")
    const [img, setImg] = useState("")
    const [url, setUrl] = useState("")

    const getData = async () => {
        let fields = await getEntryById(id)

        let fieldsOfFields = fields.fields

        setImg(fieldsOfFields.profileImage.fields.file.url)
        setName(fieldsOfFields.companyName)
        setUrl(fieldsOfFields.partnerUrl)
    }

    useEffect(() => {
        if (name == "" && img == "") getData()
    }, [])

    return (
        <a href={url}>
            <div className={`w-[170px] xs:w-[220px] sm:w-[170px] md:w-[220px] h-[190px] xs:h-[250px] sm:h-[190px] md:h-[250px] ${theme == "dark" ? "bg-darker border border-dgray" : "bg-white border-border-lgray"} flex flex-col items-center justify-between rounded-[20px] overflow-hidden pb-2 cursor-pointer`}>
                <h1 className={(theme == "dark" ? "bg-dark text-white" : "bg-dwhite text-ldark") + " w-full py-2 text-center font-semibold"}>{name}</h1>
                <img src={img} className="w-[60%] xs:w-[85%] sm:w-[60%] md:w-[85%]" />
            </div>
        </a>
    )
}

const Message = ({
    from = "",
    content = "",
    classes = "",
    service = null,
    question = ""
}) => {
    const { image } = useContext(UsernameContext)
    const { username, email, loggedIn } = useContext(UsernameContext)
    const { theme } = useContext(ThemeContext)
    const ref = useRef(null)
    const [open, setOpen] = useState(false)
    const [x, setX] = useState(0)
    const [y, setY] = useState(0)
    const [pushed, setPushed] = useState(false)

    useEffect(() => {
        if (service == "gpt") {
            ref.current.addEventListener("contextmenu", (e) => {
                e.preventDefault()
                setOpen(true)
                setX(e.pageX)
                setY(e.pageY)
            })
        }
    }, [])

    const push = async () => {
        if (service == "gpt") {
            const toastId = toast.loading("Processing your request")
            if(!pushed){
                let res = await pushRequest(question, content, username)

                if (!res.failed) {
                    toast.update(toastId, {
                        render: "Push request sent",
                        type: "success",
                        isLoading: false,
                        autoClose: 2000
                    })

                    setPushed(true)

                    if(loggedIn){
                        const tempUser = await authLogin()
                        const currentCampaign = await getCampaigns()

                        const campaignTickets = await getTickets(currentCampaign.id)

                        console.log("UTENTE =>", tempUser)
                        console.log("CAMPAGNA =>", currentCampaign)

                        let myTickets = []

                        for(let i = 0; i < campaignTickets.lenght; i++) {
                            let ticket = campaignTickets[i]
                            if(ticket.user_id == tempUser._id) myTickets.push(ticket)
                        }

                        if(myTickets.length < currentCampaign.max_tickets){
                            console.log(username, "--", email)

                            let res_ticket = await assignTicket(username, email)

                            console.log(res_ticket)

                            if(!res_ticket.failed){
                                toast("Congratulation, you've been assigned ticket #" + res_ticket.ticket.id + + " for campaign " + res_ticket.ticket.campaign, {
                                    type: "success"
                                })
                            }
                        } else console.log("Limit reached")
                    }
                }
                else {
                    toast.update(toastId, {
                        render: res.error,
                        type: "error",
                        isLoading: false,
                        autoClose: 2000
                    })
                }
            }
            else{
                toast.update(toastId, {
                    render: "This message has already been pushed",
                    type: "error",
                    isLoading: false,
                    autoClose: 2000
                })
            }
        }
    }

    return (
        <>
            <div
                className={`
                    w-full flex justify-end ${from == "service" ?
                        "flex-row-reverse"
                        :
                        ""
                    }
                ` + classes}
            >
                {
                    service == "gpt" &&
                    <BiArchiveIn 
                        size={20} 
                        color={theme == "dark" ? "#FFF" : "#12121a"}
                        className="cursor-pointer mt-5 ml-2"
                        onClick={push}
                    />
                }
                <div ref={ref} className={`
                    rounded-[20px] 
                    w-fit max-w-[75%] h-fit
                    mt-3 py-2 px-4 
                    text-white font-semibold
                    ${from != "service" ?
                        "bg-gradient-to-r from-slblue to-dblue mr-3"
                        :
                        "bg-ldark bg-opacity-30 ml-3"
                    }
                `}>
                    {content}
                </div>

                <div className={`
                    w-[50px] h-[50px] 
                    rounded-full
                    ${from != "service" ?
                        "bg-gradient-to-r from-slblue to-dblue"
                        :
                        "bg-ldark bg-opacity-30"
                    }
                    flex items-center justify-center overflow-hidden
                `}>
                    {
                        from != "service" ?
                            image != "" && <img src={image} className="w-[55px] h-[55px]" />
                            :
                            <img src={theme == "dark" ? openai_dark : openai_light} className="w-[55px] h-[55px]" />
                    }
                </div>
            </div>
            {
                service == "gpt" &&
                <ChoicesMenu
                    choices={["Push answer to DB"]}
                    actions={[push]}
                    opened={open}
                    closePanel={() => setOpen(false)}
                    top={y}
                    left={x}
                />
            }
        </>
    )
}

const LoadingMessage = () => {
    return (
        <div className={`w-full flex justify-end flex-row-reverse mt-2 animate-pulse`}>
            <div className={`
                rounded-[20px] 
                w-[100px] h-[40px]
                mt-3 py-2 px-4 
                text-white font-semibold
                bg-ldark bg-opacity-30 ml-3
            `}>
            </div>

            <div className={`
                w-[50px] h-[50px] 
                rounded-full
                bg-ldark bg-opacity-30
                
            `}>

            </div>
        </div>
    )
}

const SearchResult = ({
    prompt = "",
    answer = "",
    username: usr = "",
    timestamp = 0,
    id = "",
    classes = "",
    handleVote = async () => { }
}) => {
    const [opened, open] = useState(false)
    const [majority, setMajority] = useState(true)
    const [totalVotes, setTotalVotes] = useState(0)
    const { theme } = useContext(ThemeContext)

    const exec = async () => {
        let votes = await getQueryVotes(id)

        if (votes.length > 0) {
            setTotalVotes(votes.length)
            let positive = votes.filter((vt) => vt.vote == true)
            let negative = votes.filter((vt) => vt.vote == false)

            setMajority(positive.length >= negative.length)
        }
    }

    useEffect(() => {
        exec()
    }, [])

    return (
        <div className={(theme == "dark" ? "bg-dark border border-dgray" : "bg-lighter border border-lgray") + " w-full py-2 rounded-[15px] heightTrans overflow-hidden h-fit " + (!opened && "max-h-[90px] ") + classes}>
            <div className="w-full grid grid-cols-6 gap-3">

                <div className="hidden xs:flex flex-col items-center h-[70px] col-span-2 sm:col-span-1">
                    <div className="w-[50px] h-[50px] bg-cobalto rounded-xl">

                    </div>
                    <p className={(theme == "dark" ? "text-neutral-300" : "text-ldark") + " text-xs mt-1"}>@{usr.length > 10 ? usr.substring(0, 8) + ".." : usr}</p>
                </div>

                <div className={"col-span-5 xs:col-span-4 sm:col-span-3 2sp:col-span-2 flex items-center h-full justify-center font-semibold text-xl h-[70px] xs:h-full " + (theme == "dark" ? "text-neutral-300" : "text-ldark")}>
                    "{prompt.length > 10 ? prompt.substring(0, 7) + "..." : prompt}"
                </div>

                <div className={(theme == "dark" ? "text-neutral-300" : "text-ldark") + " col-span-1 hidden md:flex items-center justify-center font-semibold h-full"}>
                    {formatDate(timestamp)}
                </div>

                <div className="col-span-1 hidden 2sp:flex flex-col items-center justify-center">
                    <FaChevronDown
                        size={25}
                        color={"#74CB62"}
                        className="rotate-180 mb-[-8px] cursor-pointer"
                        onClick={() => {
                            handleVote(1).then(() => exec())
                        }}
                    />
                    <p className={(majority ? "text-lgreen" : "text-lred") + " font-semibold text-lg"}>{totalVotes}</p>
                    <FaChevronDown
                        size={25}
                        color={"#D04530"}
                        className="mt-[-7px] cursor-pointer"
                        onClick={() => {
                            handleVote(-1).then(() => exec())
                        }}
                    />
                </div>

                <div
                    className={(theme == "dark" ? "text-lgray" : "text-ldark") + " col-span-2 md:col-span-1 hidden sm:flex items-center h-full justify-center font-semibold cursor-pointer"}
                    onClick={() => open(!opened)}
                >
                    View {opened ? "less" : "more"}
                </div>

                <div className="flex items-center justify-center col-span-1 xs:hidden">
                    <FaChevronDown
                        size={20}
                        color="#CBCBCB"
                        onClick={() => open(!opened)}
                        className={"transition-all " + (opened && "rotate-180")}
                    />
                </div>
            </div>
            <div className="w-full mt-7 px-3 pb-2">
                <p className={(theme == "dark" ? "text-neutral-300" : "text-ldark") + " font-semibold block md:hidden"}>
                    Published on {formatDate(timestamp)}
                    <br className="block xs:hidden" />
                    <span className="block xs:hidden">
                        By <span className="text-lblue">@{usr.length > 10 ? usr.substring(0, 8) + ".." : usr}</span>
                    </span>
                </p>
                <p className={(theme == "dark" ? "text-neutral-300" : "text-ldark") + " flex items-center 2sp:hidden mt-1 font-semibold"}>
                    Votes:
                    <span className="flex items-center ml-3">
                        <FaChevronDown
                            size={18}
                            color={"#74CB62"}
                            className="rotate-180 cursor-pointer"
                            onClick={() => {
                                handleVote(1).then(() => exec())
                            }}
                        />
                        <p className={(majority ? "text-lgreen" : "text-lred") + " font-semibold text-lg ml-1"}>{totalVotes}</p>
                        <FaChevronDown
                            size={18}
                            color={"#D04530"}
                            className="ml-1 cursor-pointer"
                            onClick={() => {
                                handleVote(-1).then(() => exec())
                            }}
                        />
                    </span>
                </p>

                <p className="w-full font-semibold text-lblue mt-3 md:mt-0">Full Prompt</p>
                <p className={(theme == "dark" ? "text-neutral-300" : "text-ldark") + " w-full font-semibold text-lg mt-1 md:mt-0"}>{prompt}</p>

                <p className="w-full font-semibold text-lblue mt-3 md:mt-0">Result</p>
                <p className={(theme == "dark" ? "text-neutral-300" : "text-ldark") + " w-full font-semibold text-lg mt-1 md:mt-0"}>{answer}</p>
            </div>
        </div>
    )
}

const LoadingSearchResult = ({ classes = "" }) => {
    return (
        <div className={"w-full bg-lgray bg-opacity-30 px-3 py-2 rounded-[15px] h-fit animate-pulse flex items-center " + classes}>
            <div className="rounded-[10px] bg-ldark bg-opacity-80 p-2 cursor-pointer transition-all min-w-[48px] h-[48px]" />
            <div className="rounded-[10px] bg-ldark bg-opacity-80 cursor-pointer transition-all w-full h-[10px] ml-5" />
            <div className="rounded-[10px] bg-ldark bg-opacity-80 cursor-pointer transition-all w-full h-[10px] ml-5" />
        </div>
    )
}

export default Dashboard