import { useContext } from "react"
import { ThemeContext } from "../contexts/themeContext"
import { UsernameContext } from "../contexts/userContext"
import { FrameContext } from "../contexts/frameContext"
import imageBg from "../media/partners_box.png"

const Header = ({
    text = "",
    classes = ""
}) =>{
    const { theme } = useContext(ThemeContext)
    const { loggedIn, image } = useContext(UsernameContext)
    const { frame, setFrame } = useContext(FrameContext)
    
    return(
        <div className={"w-full flex items-center justify-between lg:pl-10 pr-5 xxs:pr-10 md:pr-20 pt-10 " + classes}>
            <h1 className={(theme == "dark" ? "text-white" : "text-dark") + " text-4xl font-semibold"}>{text}</h1>
            {
                frame != "register" && frame != "login" ?
                    loggedIn ?
                        <div 
                            className="w-[50px] h-[50px] flex items-center justify-center rounded-full overflow-hidden cursor-pointer"
                            onClick={() => { setFrame("login") }}
                        >
                            <img src={image != "" ? image : imageBg} className="h-full"/>
                        </div>
                        :
                        <p 
                            className={(theme == "dark" ? "text-white" : "text-dark") + " text-xl font-semibold cursor-pointer"}
                            onClick={() =>{
                                setFrame("login")
                            }}
                        >
                            Login
                        </p>
                    :
                    <></>
            }
        </div>
    )
}

export default Header